import './loader.css';
import React from "react";

function Loader(props) {
    return props.text && props.text == true ? <>
        <div className={"myWrapper"}>
            <p style={{marginTop: 100 ,textAlign: 'center'}}>Il tuo account è stato cancellato, a breve verrai reindirizzato a Validato.it</p>
            <div className="lds-roller">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>

        </div>  </> :
        <>
            <div className={"myWrapper"}>
                <div className="lds-roller">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
    </>;
}
export default Loader;
