import React, {Component} from "react";
import HorizontalLinearStepper from "../components/stepper";
import FormControl from "@material-ui/core/FormControl";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import ExitIcon from "@material-ui/icons/ExitToApp";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import {toast, ToastContainer} from "react-toastify";
import {Link, Redirect} from "react-router-dom";
import api from "../api/index";
import {
    CircularProgress,
    Input,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    TextField,
    Tooltip
} from "@material-ui/core";
import {brown} from "@material-ui/core/colors";
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelActions from '@material-ui/core/ExpansionPanelActions';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import VisibilityIcon from '@material-ui/icons/Visibility';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import "react-toastify/dist/ReactToastify.css";
import InputAdornment from "@material-ui/core/InputAdornment";
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import {forEach} from "react-bootstrap/ElementChildren";
import PhoneInput from "react-phone-number-input/input";

const Uppy = require('@uppy/core')
const Italian = require('@uppy/locales/lib/it_IT')
const { Dashboard, DashboardModal, DragDrop, ProgressBar } = require('@uppy/react')
const Tus = require('@uppy/tus')

const uppy = new Uppy({
    locale: Italian,
    restrictions: {
        maxFileSize: 1440000,
        maxNumberOfFiles: 6,
        minNumberOfFiles: 1,
        allowedFileTypes: ['image/*']
    },
});


export default class Profilo extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            open: false,
            login: true,
            isNotLogged: false,
            isPrivateUser: true,
            hidePwd: true,
            openElimina: false,
            deletePwd: '',
            iphoneExtra: [
                {
                    "domanda": "In che stato si trova la fotocamera del tuo dipositivo?",
                    "type": "1",
                    "label": "Fotocamera",
                    "key":"fotocamera",
                    "risposte":[
                        "Perfetta",
                        "Con Problemi",
                        "Danneggiata"
                    ]
                },
                {
                    "domanda": "In che stato si trova il touch screen del tuo dipositivo?",
                    "type": "1",
                    "label": "Touch Screen",
                    "key":"touchScreen",
                    "risposte":[
                        "Perfetto",
                        "Con Problemi",
                        "Danneggiato"
                    ]
                },
                {
                    "domanda": "In che stato si trova la batteria del tuo dipositivo?",
                    "type": "1",
                    "label": "Stato Batteria",
                    "key":"statoBatteria",
                    "risposte":[
                        "Perfetto Stato",
                        "Normale usura",
                        "Scarso stato",
                        "Danneggiata"
                    ]
                },
                {
                    "domanda": "Hai la scatola originale? Se sì in che condizioni si trova?",
                    "finalQuestions": true,
                    "type": "1",
                    "label": "Condizioni scatola",
                    "key":"scatola",
                    "risposte":[
                        "Perfetto Stato",
                        "Normale usura",
                        "Scarso stato",
                        "Non presente"
                    ]
                },
                {
                    "domanda": "Hai gli accessori originali? In che condizioni ?",
                    "finalQuestions": true,
                    "type": "1",
                    "label": "Condizioni accessori",
                    "key":"accessori",
                    "risposte":[
                        "Perfetto Stato",
                        "Normale usura",
                        "Scarso stato",
                        "Non presenti"
                    ]
                }
            ],
            ipadExtra: [
                {
                    "domanda": "In che stato si trova la fotocamera del tuo dipositivo?",
                    "type": "1",
                    "label": "Fotocamera",
                    "key":"fotocamera",
                    "risposte":[
                        "Perfetta",
                        "Con Problemi",
                        "Danneggiata"
                    ]
                },
                {
                    "domanda": "In che stato si trova il touch screen del tuo dipositivo?",
                    "type": "1",
                    "label": "Touch Screen",
                    "key":"touchScreen",
                    "risposte":[
                        "Perfetto",
                        "Con Problemi",
                        "Danneggiato"
                    ]
                },
                {
                    "domanda": "In che stato si trova la batteria del tuo dipositivo?",
                    "type": "1",
                    "label": "Stato Batteria",
                    "key":"statoBatteria",
                    "risposte":[
                        "Perfetto Stato",
                        "Normale usura",
                        "Scarso stato",
                        "Danneggiata"
                    ]
                },
                {
                    "domanda": "Che tipo di connettività ha il tuo dispositivo?",
                    "type": "1",
                    "label": "Connettività",
                    "key":"connetivita",
                    "risposte":[
                        "Wi-fi",
                        "Cellular"
                    ]
                },
                {
                    "domanda": "Qual è l'anno di produzione del tuo dispositivo ? ",
                    "type": "2",
                    "label": "Anno Produzione",
                    "key":"annoProduzione",
                    "risposte":[]
                },
                {
                    "domanda": "Hai la scatola originale? Se sì in che condizioni si trova?",
                    "finalQuestions": true,
                    "type": "1",
                    "label": "Condizioni scatola",
                    "key":"scatola",
                    "risposte":[
                        "Perfetto Stato",
                        "Normale usura",
                        "Scarso stato",
                        "Non presente"
                    ]
                },
                {
                    "domanda": "Hai gli accessori originali? In che condizioni ?",
                    "finalQuestions": true,
                    "type": "1",
                    "label": "Condizioni accessori",
                    "key":"accessori",
                    "risposte":[
                        "Perfetto Stato",
                        "Normale usura",
                        "Scarso stato",
                        "Non presenti"
                    ]
                }
            ],
            appleTvExtra: [{
                "domanda": "Hai la scatola originale? Se sì in che condizioni si trova?",
                "finalQuestions": true,
                "type": "1",
                "label": "Condizioni scatola",
                "key":"scatola",
                "risposte":[
                    "Perfetto Stato",
                    "Normale usura",
                    "Scarso stato",
                    "Non presente"
                ]
            },
                {
                    "domanda": "Hai gli accessori originali? In che condizioni ?",
                    "finalQuestions": true,
                    "type": "1",
                    "label": "Condizioni accessori",
                    "key":"accessori",
                    "risposte":[
                        "Perfetto Stato",
                        "Normale usura",
                        "Scarso stato",
                        "Non presenti"
                    ]
                }],
            appleWatchExtra: [{
                "domanda": "Hai la scatola originale? Se sì in che condizioni si trova?",
                "finalQuestions": true,
                "type": "1",
                "label": "Condizioni scatola",
                "key":"scatola",
                "risposte":[
                    "Perfetto Stato",
                    "Normale usura",
                    "Scarso stato",
                    "Non presente"
                ]
            },
                {
                    "domanda": "Hai gli accessori originali? In che condizioni ?",
                    "finalQuestions": true,
                    "type": "1",
                    "label": "Condizioni accessori",
                    "key":"accessori",
                    "risposte":[
                        "Perfetto Stato",
                        "Normale usura",
                        "Scarso stato",
                        "Non presenti"
                    ]
                }],
            airPodsExtra: [{
                "domanda": "Hai la scatola originale? Se sì in che condizioni si trova?",
                "finalQuestions": true,
                "type": "1",
                "label": "Condizioni scatola",
                "key":"scatola",
                "risposte":[
                    "Perfetto Stato",
                    "Normale usura",
                    "Scarso stato",
                    "Non presente"
                ]
            },
                {
                    "domanda": "Hai gli accessori originali? In che condizioni ?",
                    "finalQuestions": true,
                    "type": "1",
                    "label": "Condizioni accessori",
                    "key":"accessori",
                    "risposte":[
                        "Perfetto Stato",
                        "Normale usura",
                        "Scarso stato",
                        "Non presenti"
                    ]
                }],
            macExtra: [
                {
                    "domanda": "In che stato si trova la batteria del tuo dipositivo?",
                    "type": "1",
                    "label": "Stato Batteria",
                    "key":"statoBatteria",
                    "risposte":[
                        "Perfetto Stato",
                        "Normale usura",
                        "Scarso stato",
                        "Danneggiata"
                    ]
                },
                {
                    "domanda": "Qual è il processore del tuo Mac ? ",
                    "type": "2",
                    "label": "Processore",
                    "key":"processore",
                    "risposte":[]
                },
                {
                    "domanda": "Qual è l'anno di produzione del tuo Mac ? ",
                    "type": "2",
                    "label": "Anno Produzione",
                    "key":"annoProduzione",
                    "risposte":[]
                },
                {
                    "domanda": "Quanta ram ha il tuo Mac ",
                    "type": "2",
                    "label": "Ram",
                    "key":"ram",
                    "risposte":[]
                },
                {
                    "domanda": "Hai la scatola originale? Se sì in che condizioni si trova?",
                    "finalQuestions": true,
                    "type": "1",
                    "label": "Condizioni scatola",
                    "key":"scatola",
                    "risposte":[
                        "Perfetto Stato",
                        "Normale usura",
                        "Scarso stato",
                        "Non presente"
                    ]
                },
                {
                    "domanda": "Hai gli accessori originali? In che condizioni ?",
                    "finalQuestions": true,
                    "type": "1",
                    "label": "Condizioni accessori",
                    "key":"accessori",
                    "risposte":[
                        "Perfetto Stato",
                        "Normale usura",
                        "Scarso stato",
                        "Non presenti"
                    ]
                }
            ],
            playstationExtra: [],
            user: null,
            expanded: "",
            openReplyToMessage : false,
            openReActiveRichiesta: false
        };
    }

    async componentDidMount() {
        let tokenEsterno = this.props.location.search.split('?t=')[1];
        if(tokenEsterno){
           this.setState({tokenEsterno})
        }
        this.setState({
            isLoading: false,
            user: JSON.parse(localStorage.getItem('user')),
            openIban: localStorage.getItem('user') == null ? false : JSON.parse(localStorage.getItem('user')).iban == null ? true : false,
            isNotLogged: localStorage.getItem('user') == null,
            openLogin: localStorage.getItem('user') == null,
            isPrivateUser: localStorage.getItem('user') == null ? true : JSON.parse(localStorage.getItem('user')).tipoUtente == 1 ? true : false

        },() => {
            if(!this.state.isNotLogged) {
                this.getVendite();
                if(this.state.tokenEsterno){
                    this.getOfferta();
                }
            }
        });
    }

    getOfferta() {
        api.get('/offerte/getOfferta?t='+this.state.tokenEsterno, {
            headers: {
                Authorization: this.state.user.token
            }})
            .then((response) => {
                if(response.status == 200) {
                    console.log(response.data);
                    this.setState({
                        nomeModello: response.data.nomeModello,
                        prezzo: response.data.prezzo,
                        messaggioOfferente: response.data.messaggioOfferente,
                        openOfferta: true
                    })

                }
            })
            .catch(error => {
                console.log(error);
            })
    }

    saveUser = (event) =>  {
        event.preventDefault();
        if(this.state.recoverPwd) {
            let body = {
                email: event.target.email.value
            };
            api.post('auth/resetPwd',body)
                .then((response) => {
                    this.setState({
                        recoverPwd: false,
                    },() => {
                       this.successAcceptRecoverToast();
                    });
                })
                .catch(error => {
                    this.errorToastRecover();
                    console.log(error);
                })
        } else{
            this.setState({
                openLogin: false
            })
            if(this.state.login) {
                let body = {
                    email: event.target.email.value,
                    pwd: event.target.password.value
                };
                api.post('auth/login',body)
                    .then((response) => {
                        localStorage.setItem('user', JSON.stringify(response.data.user));
                        this.setState({
                            user: response.data.user,
                            isNotLogged: false
                        },() => {
                            this.getVendite();
                            this.getOfferta();
                        });
                    })
                    .catch(error => {
                        this.setState({
                            openLogin: true
                        })
                        if(error.response.status == 401) {
                            this.errorToast2();
                            this.setState({
                                isLoading: false
                            })
                        } else if(error.response.status == 404) {
                            this.errorToast();
                            this.setState({
                                isLoading: false
                            })
                        } else if(error.response.status == 409) {
                            this.errorToastRecover();
                            this.setState({
                                isLoading: false
                            })
                        }else {
                            this.errorToast();
                            this.setState({
                                isLoading: false
                            })
                        }
                        console.log(error);
                    })
            } else {
                let body = {
                    nome: event.target.nome.value,
                    cognome: event.target.cognome.value,
                    email: event.target.email.value,
                    cellulare: event.target.cellulare.value,
                    indirizzo: event.target.via.value,
                    citta: event.target.citta.value,
                    pwd: event.target.password.value,
                    numero: event.target.numero.value,
                    regione: event.target.regione.value,
                    provincia: event.target.provincia.value,
                    tipoUtente: this.state.isPrivateUser? 1 : 2,
                    codiceFiscale: this.state.isPrivateUser ? event.target.codiceFiscale.value : null,
                    piva: !this.state.isPrivateUser ? event.target.pIva.value : null,
                    nomeAzienda: !this.state.isPrivateUser ? event.target.azienda.value : null,
                    cap: event.target.cap.value,
                    username: event.target.username.value
                };
                api.post('auth/registrati',body)
                    .then((response) => {
                        localStorage.setItem('user', JSON.stringify(response.data.user));
                        this.setState({
                            user: response.data.user,
                            isNotLogged: false
                        },() => {
                            this.getVendite();
                            this.getOfferta();
                        });
                    })
                    .catch(error => {
                        this.setState({
                            openLogin: true
                        })
                        this.errorToastRegister();
                        this.setState({
                            isLoading: false
                        })
                        console.log(error);
                    })
            }
        }
    }


    errorToast = () => toast.error("❌️ Le credenziali non sono valide!", {
        position: toast.POSITION.TOP_RIGHT,
    });
    errorToastUsername = () => toast.error("❌️ L'username da te scelto è già in uso!", {
        position: toast.POSITION.TOP_RIGHT,
    });
    errorToastRecover = () => toast.error("❌️ La mail inserita non è registrata!", {
        position: toast.POSITION.TOP_RIGHT,
    });
    errorToast2 = () => toast.error("❌️ La password inserita non è valida!", {
        position: toast.POSITION.TOP_RIGHT,
    });
    successAcceptToast = () => toast.success("✔️ Offerta accettata con successo!", {
        position: toast.POSITION.TOP_RIGHT,
    });
    successUpdateToast = () => toast.success("✔️ Immagini caricate con successo!", {
        position: toast.POSITION.TOP_RIGHT,
    });
    successAcceptRecoverToast = () => toast.success("✔️ Email inviata con successo!", {
        position: toast.POSITION.TOP_RIGHT,
    });
    successDeleteToast = () => toast.success("✔️ Offerta rifiutata!", {
        position: toast.POSITION.TOP_RIGHT,
    });
    successPwdModToast = () => toast.success("✔️ Password modificata con successo!", {
        position: toast.POSITION.TOP_RIGHT,
    });
    errorPwdModToast = () => toast.error("✔️ La vecchia password inserita è errata!", {
        position: toast.POSITION.TOP_RIGHT,
    });

    errorToastRegister = () => toast.error("❌️ La mail inserita è già registrata!", {
        position: toast.POSITION.TOP_RIGHT,
    });

    handleChangePanel = (panel) => (event, isExpanded) => {
        this.setState({
            expanded: isExpanded ? panel : false
        });
    };

    handleCloseSendReplyToMessage = () => {

    };

    getVendite() {
        api.get('user/getInfoUser', {
            headers: {
                Authorization: this.state.user.token
            }})
            .then((response) => {
                if(response.data.richieste.length > 0) {
                    this.setState({
                        expanded: 'panel0'
                    })

                }
                this.setState({
                    richieste: response.data.richieste,
                    isLoading: false
                })
            })
            .catch(error => {
                console.log(error);
            })
    }

    handleClose() {
        this.setState({
            open: false,
        });
    }
    handleCloseLogin() {
        this.setState({
            openLogin: false,
        });
    }


    handleCloseIban() {
        this.setState({
            openIban: false,
        });
    }

    handleClosePwd() {
        this.setState({
            openModPwd: false
        })
    }
    handleDeleteAccount() {
        this.setState({
            openElimina: false
        })
    }

    handleClosePrice() {
        this.setState({
            openPrice: false,
        });
        setTimeout(() => {
            this.setState({toModify: null});
        }, 500);
    }
    dataURLtoFile(dataurl) {
        fetch(dataurl)
            .then(res => res.blob()) // Gets the response and returns it as a blob
            .then(blob => {
                blob.lastModifiedDate = new Date();
                blob.name = 'prova.jpg';
                return blob;
            });
    }

    b64toBlob(dataURI) {

        var byteString = atob(dataURI.split(',')[1]);
        var ab = new ArrayBuffer(byteString.length);
        var ia = new Uint8Array(ab);

        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ab], { type: 'image/jpeg' });
    }

    async handleOpenPrice(data) {
        uppy.cancelAll();
        data.images.forEach((elm,index) => {

            console.log(elm);
            if(typeof elm === 'object' && elm !== null) {
                fetch(elm.src)
                    .then(res => res.blob()) // Gets the response and returns it as a blob
                    .then(blob => {
                        blob.lastModifiedDate = new Date();
                        blob.name = "prova1.jpg";
                        blob.isFromStart = true;
                        console.log(blob);
                        uppy.addFile({
                            source: 'file input',
                            name: index.toString(),
                            type: "image/jpg",
                            data: blob
                        })
                    });
            } else {
                var blob = this.b64toBlob(elm);
                blob.lastModifiedDate = new Date();
                blob.name = "prova1.jpg";
                blob.isFromStart = true;
                console.log(blob);
                uppy.addFile({
                    source: 'file input',
                    name: index.toString(),
                    type: "image/jpg",
                    data: blob
                })
            }

        })

        this.setState({
            toModify: data,
            openPrice: true,
        });
    }

    handleOpen() {
        this.setState({
            open: true
        })
    }
    handleOpenIban() {
        this.setState({
            openIban: true
        })
    }
    updateUserIban = (event) => {
        event.preventDefault();
        let tmpUser = this.state.user;
        tmpUser["iban"] = event.target.iban.value;
        api.post('user/editUser',tmpUser, {
            headers: {
                Authorization: this.state.user.token
            }})
            .then((response) => {
                if(response.status == 200) {
                    this.updatedToast();
                    localStorage.setItem('user', JSON.stringify(response.data.utente));
                    this.setState({
                        user: response.data.utente
                    })
                }
                this.setState({
                    openIban: false
                })

            })
            .catch(error => {
                console.log(error);
            })
    }
    handleReplyToMessage = () => {
        this.setState({
            openReplyToMessage: true
        })
    }

    changePwd = (event) => {
        event.preventDefault();
        let body = {
            "oldPwd" : event.target.pwdOld.value,
            "newPwd": event.target.pwdNew.value
        }
        api.post('user/editPwd',body, {
            headers: {
                Authorization: this.state.user.token
            }})
            .then((response) => {
                if(response.status == 200) {
                    this.successPwdModToast();
                    this.handleClosePwd();
                }
            })
            .catch(error => {
                this.errorPwdModToast();
                console.log(error);
            })
    }

    handleCloseReplyToMessage = async () => {
        this.setState({
            openReplyToMessage: false
        })
    }

    handleCloseReOpenRichiesta = async () => {
        this.setState({
            openReActiveRichiesta: false
        })
    }

    updatePrice = async (event) => {
        event.preventDefault();

        var imgBase64 = [];

        const idRichiestaToMod = this.state.toModify.idRichiesta;

        for (const [key, value] of Object.entries(uppy?.state?.files)) {
            const result = await this.toBase64(value.data);
            imgBase64.push(result);
        }

        let elmToChange;
        this.state.richieste.forEach((elm, index) => {
            if(elm.idRichiesta == idRichiestaToMod) {
                elmToChange = elm;
            }
        })

        let isModified = false;
        if(Object.entries(uppy?.state?.files).length != elmToChange.images.length){
            isModified = true
        } else if (Object.entries(uppy?.state?.files).length == elmToChange.images.length) {
            for (const [key, value] of Object.entries(uppy?.state?.files)) {
               if(!value.data.isFromStart) {
                   isModified = true;
                   break;
               }
            }
        }
        if(elmToChange.descrizioneRichiesta != event.target.descrizioneRichiesta.value) {
            isModified = true;
        }
        this.state.richieste.forEach((elm, index) => {
            if(elm.idRichiesta == idRichiestaToMod) {
                this.state.richieste[index].images = imgBase64
            }
        })
        let body = {
            "idRichiesta" : this.state.toModify.idRichiesta,
            "prezzo": event.target.prezzo.value,
            "descrizioneRichiesta": event.target.descrizioneRichiesta.value,
            "img": imgBase64,
            "isModified": isModified
        }
        this.handleClosePrice();
        api.post('user/editRichiesta',body, {
            headers: {
                Authorization: this.state.user.token
            }})
            .then((response) => {
                if(response.status == 200) {
                    api.get('user/getInfoUser', {
                        headers: {
                            Authorization: this.state.user.token
                        }})
                        .then((response) => {
                            response.data.richieste.forEach((elm, index) => {
                                if(elm.idRichiesta == idRichiestaToMod) {
                                    response.data.richieste[index].images = imgBase64
                                }
                            })
                            this.setState({
                                richieste: response.data.richieste
                            })

                        })
                        .catch(error => {
                            console.log(error);
                        })
                }

            })
            .catch(error => {
                console.log(error);
            })
    }
    handleChangeAzienda = (event) => {
        this.setState({
            isPrivateUser: !this.state.isPrivateUser
        })
    }

    sendReplyToMessage = (event) => {

        event.preventDefault();
        let body =
            {
                "messaggio": event.target.msg.value,
                "nomeOfferente": this.state.nomeOfferente,
                "emailOfferente": this.state.emailOfferente
            }

            console.log(body);
        api.post('/offerta/replyMessage',body, {
            headers: {
                Authorization: this.state.user.token
            }})
            .then((response) => {
                if(response.status == 200) {
                    this.setState({
                        openReplyToMessage : false
                    })
                }

            })
            .catch(error => {
                console.log(error)
                //this.errorToast2();
            })
    };

    handleCloseDelete() {
        this.setState({
            openDelete: false
        },() => {
            var that = this;
            setTimeout(function () {
                that.setState({
                    toDelete: null
                })
            }, 500);

        })
    }
    deleteAccount = (event) => {
        event.preventDefault()
        const body = {
            "pwd" : event.target.pwdDelete ?  event.target.pwdDelete.value : ""
        }
        if(body.pwd != "")
        {
            api.post('user/delete',body, {
                headers: {
                    Authorization: this.state.user.token
                }})
                .then((response) => {
                    console.log(response)
                    if(response.status == 200) {
                        this.props.history.push('/accountEliminato')
                    }

                })
                .catch(error => {
                    console.log(error)
                    this.errorToast2();
                })
        }

    }



    deleteAnnuncio() {
        const body = {
            "idRichiesta": this.state.toDelete.idRichiesta
        }
        api.post('user/deleteRichiesta',body, {
            headers: {
                Authorization: this.state.user.token
            }})
            .then((response) => {
                console.log(response);
                if(response.status == 200) {
                    this.delteteToast();
                    this.getVendite();
                    this.handleCloseDelete();
                }
            })
            .catch(error => {
            })
    }

    showHidePwd() {
        this.setState({
            hidePwd: !this.state.hidePwd
        })
    }
    updateUser = (event) => {
        event.preventDefault();
        let body = {
            nome: event.target.nome.value,
            cognome: event.target.cognome.value,
            email: event.target.email.value,
            cellulare: event.target.cellulare.value,
            indirizzo: event.target.via.value,
            citta: event.target.citta.value,
            iban: event.target.iban.value,
            numero: event.target.numero.value,
            regione: event.target.regione.value,
            provincia: event.target.provincia.value,
            tipoUtente: this.state.isPrivateUser? 1 : 2,
            codiceFiscale: this.state.isPrivateUser ? event.target.codiceFiscale.value : null,
            piva: !this.state.isPrivateUser ? event.target.pIva.value : null,
            nomeAzienda: !this.state.isPrivateUser ? event.target.azienda.value : null,
            cap: event.target.cap.value,
            username: event.target.username.value
        };
        api.post('user/editUser',body, {
            headers: {
                Authorization: this.state.user.token
            }})
            .then((response) => {
                console.log(response);
                if(response.status == 200) {
                    this.updatedToast();
                    localStorage.setItem('user', JSON.stringify(response.data.utente));
                    this.setState({
                        user: response.data.utente
                    })
                }
                this.setState({
                    open: false
                })

            })
            .catch(error => {
                if(error.response.status == 409)
                {
                    this.errorToastUsername();
                }
            })
    }

    logout() {
        localStorage.removeItem('user');
        this.props.history.push('/');
    }

    handleCloseOfferta() {
        this.setState({
            openOfferta: false
        })
    }

    handleRejectOfferta() {
        if(this.state.clickFromCard) {
            let body = {
                idOfferta: this.state.idOffertaSelected
            };
            api.post('offerte/rifiutaOfferta', body, {
                headers: {
                    Authorization: this.state.user.token
                }})
                .then((response) => {
                    if(response.status == 200) {
                        this.getVendite();
                        this.successDeleteToast();
                        this.handleCloseOfferta();
                        this.setState({
                            idOffertaSelected: null,
                            idRichiestaSelected: null,
                            clickFromCard: false
                        })
                    }
                })
                .catch(error => {
                    console.log(error);
                })
        } else {
            api.post('offerte/rifiutaOfferta?t='+this.state.tokenEsterno,{}, {
                headers: {
                    Authorization: this.state.user.token
                }})
                .then((response) => {
                    if(response.status == 200) {
                        this.getVendite();
                        this.successDeleteToast();
                        this.handleCloseOfferta();
                    }
                })
                .catch(error => {
                    console.log(error);
                })
        }

    }

    openDelete(data) {
        this.setState({
            openDelete: true,
            toDelete: data
        })
    }

    getArrayOfQuestions(richiesta) {
        if(richiesta.nomeProdotto.toLowerCase().includes('mac')) {
            return this.state.macExtra;
        } else if(richiesta.nomeProdotto.toLowerCase().includes('ipad')) {
            return this.state.ipadExtra;
        } else if (richiesta.nomeProdotto.toLowerCase().includes('apple tv')){
            return this.state.appleTvExtra;
        } else if (richiesta.nomeProdotto.toLowerCase().includes('apple watch')){
            return this.state.appleWatchExtra;
        } else if (richiesta.nomeProdotto.toLowerCase().includes('air pods')){
            return this.state.airPodsExtra;
        } else if(richiesta.nomeProdotto.toLowerCase().includes('playstation')){
            return this.state.playstationExtra;
        }
        else {
            return this.state.iphoneExtra
        }
    }

    republishRichiesta(event){
        event.preventDefault();
        if(event.target.prezzoToEdit.value && event.target.dayForRemove.value)
        {
            api.post('/richieste/republish',{
                idRichiesta: this.state.toModify.idRichiesta,
                prezzoToEdit: event.target.prezzoToEdit.value,
                dayForRemove: event.target.dayForRemove.value
            }, {
                headers: {
                    Authorization: this.state.user.token
                }})
                .then((response) => {
                    if(response.status == 200) {
                        window.location.reload()
                    }
                })
                .catch(error => {
                    console.log(error);
                })
        }

    }

    handleAcceptOfferta() {
        if(this.state.clickFromCard) {
            let body = {
                idOfferta: this.state.idOffertaSelected,
                idUtente: this.state.user?.idUtente,
                idRichiesta: this.state.idRichiestaSelected
            };
            console.log(body);
            api.post('offerte/accettaOfferta', body, {
                headers: {
                    Authorization: this.state.user.token
                }})
                .then((response) => {
                    if(response.status == 200) {
                        this.getVendite();
                        this.successAcceptToast();
                        this.handleCloseOfferta();
                        this.setState({
                            idOffertaSelected: null,
                            idRichiestaSelected: null,
                            clickFromCard: false
                        })
                    }
                })
                .catch(error => {
                    console.log(error);
                })
        } else {
            api.post('offerte/accettaOfferta?t='+this.state.tokenEsterno,{}, {
                headers: {
                    Authorization: this.state.user.token
                }})
                .then((response) => {
                    if(response.status == 200) {
                        this.getVendite();
                        this.successAcceptToast();
                        this.handleCloseOfferta();
                    }
                })
                .catch(error => {
                    console.log(error);
                })
        }

    }
    handleCloseModal () {
       this.setState({
           idRichiestaSelezionata: -1,
           openImgRichiesta: false
       })
    }

    toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

    updateMyPezzotto() {
        this.setState({
            tmp: Math.random()+10000
        })
    };

    render() {

        uppy.on('upload', async (result) => {
            var imgBase64 = [];

            for (const [key, value] of Object.entries(uppy?.state?.files)) {
                const result = await this.toBase64(value.data);
                imgBase64.push(result);
            }
            let bodyNew = {
                "idRichiesta": this.state.idRichiestaSelezionata,
                "img" : imgBase64
            };
            if(imgBase64.length > 0) {
                let elmToChange = this.state.richieste.find((elm) => elm.idRichiesta == this.state.idRichiestaSelezionata);
                if(elmToChange)
                    elmToChange['hasImage'] = true;


                this.setState({
                    idRichiestaSelezionata: -1,
                    openImgRichiesta: false
                });
                api.post('richieste/addImgToRichiesta',bodyNew, {
                    headers: {
                        Authorization: JSON.parse(localStorage.getItem('user')).token
                    }})
                    .then((response) => {
                        this.getVendite();
                    })
                    .catch(error => {
                        console.log(error);
                    })
            }


        });
        return (
            <div className="blank-wrapper">
                <ToastContainer autoClose={3000}/>
                <span>
                                    <img style={{padding: 20}} width={180} src='../../assets/imgs/appLogo.png' alt=""/>
                                    <span onClick={() => this.logout()} style={{color: "black"}}
                                          className={"myAccess"}>   Esci <ExitIcon/> </span>
                                    <span style={{cursor: "auto"}}
                                          className={"myAccess"}>{JSON.parse(localStorage.getItem('user')) ? "Ciao, " + JSON.parse(localStorage.getItem('user')).nome : "Accedi"}</span>
                </span>



                {
                    this.state.isLoading ? (
                        <div className="overlay">
                            <div className="overlay__inner">
                                <div className="overlay__content"><span className="spinner"></span></div>
                            </div>
                        </div>
                    ) : this.state.isNotLogged ? (
                        <div>
                            <div className="row">
                                <div className="col-sm">

                                </div>
                                <div style={{padding: "40px"}} className="col-sm-10">
                                    <h1>Benvenuto Mario</h1>
                                    <div className="row">
                                        <div className="col-sm">
                                            <br/>
                                            <div className="row myContainer">
                                                <h4>Lista annunci</h4>
                                                <span style={{width: "20px"}}></span>
                                                <button
                                                    color="primary"
                                                    className={"myButtonOutlined"}
                                                    onClick={() => this.props.history.push('./')}
                                                >
                                                    Vendi Nuovo
                                                </button>
                                            </div>
                                            <br/>
                                            <ExpansionPanel className={"myExpansionPanel"}>
                                                <ExpansionPanelSummary
                                                    expandIcon={
                                                        <ExpandMoreIcon/>
                                                    }
                                                    aria-controls="panel1c-content"
                                                    id="panel1c-header"
                                                >
                                                    <div style={{width: '100px'}}>
                                                        <p>Iphone X</p>
                                                    </div>
                                                    <div style={{float: "right", width: "100%"}}>
                                                        <span style={{backgroundColor: "orange"}} className={"myBadge"}>Da Inserire</span>
                                                    </div>

                                                </ExpansionPanelSummary>

                                            </ExpansionPanel>
                                            <br/>

                                        </div>
                                        <div className="col-sm">
                                            <br/>
                                            <div className="row myContainer">
                                                <h4>Le tue informazioni</h4>
                                                <span style={{width: "20px"}}></span>
                                                <button
                                                    color="primary"
                                                    className={"myButtonOutlined"}
                                                    onClick={() => this.setState({open: true})}
                                                >
                                                    Modifica
                                                </button>
                                            </div>
                                            <br/>
                                            <table>
                                                <tr>
                                                    <th>Nome</th>
                                                    <th>Cognome</th>
                                                    <th>Email</th>
                                                </tr>
                                                <tr>
                                                    <td>Mario</td>
                                                    <td>Rossi</td>
                                                    <td>mrossi@validato.it</td>
                                                </tr>
                                                <tr>
                                                    <th>Cellulare</th>
                                                    <th>Indirizzo</th>
                                                    <th>Iban</th>
                                                </tr>
                                                <tr>
                                                    <td>3334323231</td>
                                                    <td>Via Roma 45, Rima</td>
                                                    <td></td>
                                                </tr>
                                            </table>
                                        </div>

                                    </div>

                                </div>
                                <div className="col-sm">

                                </div>
                            </div>
                            <div className="bg-image"></div>
                        </div>

                    ) : (
                        <div>
                            <div onClick={() => this.handleOpenIban()}
                                 className={this.state.user.iban == null || this.state.user.iban == "" ? "myDanger" : "notDisplay"}>Inserisci
                                l'iban per ricevere il compenso della tua vendita
                            </div>

                            <br/>
                            <div style={{padding: "40px"}}>
                                <h1>Benvenuto {this.state.user.nome}</h1>
                                <div className="row">
                                    <div className="col-sm">
                                        <br/>
                                        <div className="row myContainer">
                                            <h4>Lista annunci</h4>
                                            <span style={{width: "20px"}}></span>
                                            <button
                                                color="primary"
                                                className={"myButtonOutlined"}
                                                onClick={() => this.props.history.push('./')}
                                            >
                                                Vendi Nuovo
                                            </button>
                                        </div>
                                        <DashboardModal
                                            open={this.state.openImgRichiesta}
                                            uppy={uppy}
                                            onRequestClose={this.handleCloseModal.bind(this)}
                                            height={400}
                                            width={"90%"}
                                            closeModalOnClickOutside
                                            note={'Carica fino a 6 immagini, dimensione massima consentita 700kb'}
                                            disableInformer={false}
                                            proudlyDisplayPoweredByUppy={false}
                                        />
                                        {this.state.richieste && this.state.richieste.map((richiesta, index) => (
                                            <div key={index}>
                                                <br/>
                                                <div className={"myExpansionPanel"} key={index} expanded={true}
                                                     onChange={this.handleChangePanel('panel' + index)}>
                                                    <div
                                                        className={"row"}
                                                        aria-controls="panel1c-content"
                                                        id="panel1c-header"
                                                        style={{padding: "16px 16px 0px 16px", minHeight: "48px"}}
                                                    >
                                                        <div className={"col-sm-9"}>
                                                            <p>{richiesta.nomeProdotto}</p>
                                                            {
                                                                (!richiesta.hasImage && richiesta.imagesCaricate == 0) && richiesta.statoRichiesta != 10 ? < >
                                                                        <div onClick={() => {
                                                                            uppy.cancelAll();
                                                                            console.log('sto qia');
                                                                            this.setState({
                                                                                idRichiestaSelezionata: richiesta.idRichiesta,
                                                                                openImgRichiesta: true
                                                                            })
                                                                        }
                                                                        }
                                                                             className="row" style={{
                                                                            fontSize: "14px",
                                                                            color: "#e40000",
                                                                            cursor: "pointer",
                                                                            paddingLeft: 5
                                                                        }}>
                                                                            <PhotoLibraryIcon style={{fontSize: "16px"}}/>
                                                                            <p style={{marginLeft: 5}}> Inserisci le foto
                                                                                del tuo dispositivo per completare la
                                                                                pubblicazione!</p>
                                                                        </div>

                                                                    </>
                                                                    : <></>
                                                            }
                                                        </div>
                                                        {richiesta.autoRimosso == 1 && richiesta.statoRichiesta == 1 ? <button
                                                            color="primary"
                                                            className={"myButtonOutlined"}
                                                            onClick={() => this.setState({
                                                                openReActiveRichiesta: true,
                                                                toModify:{
                                                                    idRichiesta: richiesta.idRichiesta,
                                                                    prezzo: richiesta.prezzo
                                                                }
                                                            })}
                                                        >
                                                            Ripubblica
                                                        </button> : ""}

                                                        <span className={"col-sm"}>
                                                            <span style={{backgroundColor: richiesta.color}}
                                                                  className={"myBadge"}>{richiesta.nomeStatoUtente}
                                                            </span>

                                                        </span>


                                                    </div>
                                                    {
                                                        richiesta.offerte && richiesta.offerte.length > 0 && richiesta.offerte.map((el) => {
                                                            if (el.disabled == 0 && el.stato == 0) {
                                                                return <span key={el.idOfferta} className={"offerDisp"}
                                                                             onClick={() => {

                                                                                 this.setState({
                                                                                     prezzo: el.prezzo,
                                                                                     idOffertaSelected: el.idOfferta,
                                                                                     idRichiestaSelected: richiesta.idRichiesta,
                                                                                     messaggioOfferente: el.messaggioOfferente,
                                                                                     emailOfferente: el.emailOfferente,
                                                                                     nomeOfferente: el.nomeOfferente,
                                                                                     openOfferta: true,
                                                                                     clickFromCard: true
                                                                                 })
                                                                             }}>
                                                                    <b>Nuovo messaggio da {el.nomeOfferente}</b>
                                                                </span>
                                                            } else {
                                                                return <span></span>
                                                            }

                                                        })

                                                    }
                                                    <div style={{padding: "16px 16px 0px 16px"}}>
                                                        <div>
                                                            <p style={{
                                                                fontSize: "12px",
                                                                color: "grey",
                                                                margin: 0
                                                            }}>{"Codice: " + richiesta.codiceRitiro}</p>
                                                            <table>
                                                                {richiesta.risposte && richiesta.risposte.length > 0 && richiesta.risposte.map((nodo, index) => (
                                                                    <span>
                                                                         <tr>
                                                                             <th>{nodo.descrizioneRisposta}</th>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>{nodo.testoRisposta}</td>
                                                                        </tr>
                                                                    </span>

                                                                ))
                                                                }
                                                                {
                                                                    this.getArrayOfQuestions(richiesta).map((risposta) =>
                                                                        <span>
                                                                        <tr>
                                                                            <th>{risposta.label}</th>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className={richiesta[risposta.key] == null ? "myRedText" : ""}>{richiesta[risposta.key] == null ? "Da Inserire" : richiesta[risposta.key]}</td>
                                                                        </tr>
                                                                    </span>)
                                                                }
                                                                <span>
                                                                    <tr>
                                                                    <th>Descrizione</th>
                                                                </tr>
                                                                <tr>
                                                                    <td>{richiesta.descrizioneRichiesta}</td>
                                                                </tr>
                                                                </span>
                                                                <br/>
                                                                {
                                                                    richiesta.statoRichiesta == 10 ?
                                                                        <></>
                                                                        : (!richiesta.hasImage && richiesta.imagesCaricate == 1 && (!richiesta.images || richiesta.images.length == 0)) ?
                                                                            <>
                                                                                <div className="row" style={{
                                                                                    fontSize: "14px",
                                                                                    paddingLeft: 5,
                                                                                    color: "grey"
                                                                                }}>
                                                                                    <p style={{marginLeft: 5}}> Immagini in
                                                                                        caricamento <CircularProgress
                                                                                            style={{
                                                                                                width: "10px",
                                                                                                height: "10px",
                                                                                                color: "grey"
                                                                                            }}/>
                                                                                    </p>
                                                                                </div>
                                                                            </> : (richiesta.hasImage && richiesta.imagesCaricate == 1 && richiesta.images.length != 0) ?
                                                                                <>
                                                                                    <div style={{
                                                                                        fontSize: "14px",
                                                                                        paddingLeft: 5,
                                                                                        color: "grey"
                                                                                    }}>
                                                                                        <p style={{marginLeft: 5}}> Immagini:</p>
                                                                                        {
                                                                                            richiesta.images.map(immagine => {
                                                                                                return <span
                                                                                                    style={{padding: 10}}>
                                                                                            <img
                                                                                                src={typeof immagine === 'object' && immagine !== null ? immagine.src : immagine}
                                                                                                alt="" height={50}/>
                                                                                        </span>
                                                                                            })
                                                                                        }
                                                                                    </div>
                                                                                </> :
                                                                                <></>
                                                                }
                                                                <div>
                                                                    {
                                                                        richiesta?.statoRichiesta != 10 &&
                                                                        <span className={"mySpacerButton"}>
                                                                             <button
                                                                                 color="primary"
                                                                                 className={"myButtonSecondaryOutlined"}
                                                                                 onClick={() => this.handleOpenPrice(richiesta)}
                                                                             >
                                                                        Modifica
                                                                    </button>
                                                                            <div
                                                                                onClick={() => this.openDelete(richiesta)}
                                                                                className={"deleteButton"}>Elimina</div>
                                                                        </span>}

                                                                    <span style={{float: "right"}}>
                                                                        <tr className={"myPriceTh"}>
                                                                            <th>Prezzo</th>
                                                                        </tr>
                                                                        <tr className={"myPriceTh"}>
                                                                            <td>{"€ " + richiesta.prezzo}</td>
                                                                        </tr>
                                                                    </span>
                                                                </div>


                                                            </table>

                                                        </div>
                                                    </div>

                                                </div>
                                                <br/>
                                            </div>
                                        ))}
                                    </div>
                                    <div className="col-sm">
                                        <br/>
                                        <div className="row myContainer">
                                            <h4>Le tue informazioni</h4>
                                            <span style={{width: "20px"}}></span>
                                            <button
                                                color="primary"
                                                className={"myButtonOutlined"}
                                                onClick={() => this.setState({open: true})}
                                            >
                                                Modifica
                                            </button>
                                            <span style={{width: "20px"}}></span>
                                            <button
                                                color="primary"
                                                className={"myButtonText"}
                                                onClick={() => this.setState({openModPwd: true})}
                                            >
                                                Modifica Password
                                            </button>
                                        </div>
                                        <br/>
                                        <table>
                                            <tr>
                                                <th>Nome</th>
                                                <th>Cognome</th>
                                                <th>Email</th>
                                            </tr>
                                            <tr>
                                                <td>{this.state.user.nome}</td>
                                                <td>{this.state.user.cognome}</td>
                                                <td>{this.state.user.email}</td>

                                            </tr>
                                            <tr>
                                                <th>Cellulare</th>
                                                <th>Indirizzo</th>
                                                <th>{this.state.user.tipoUtente == 1 ? "Codice Fiscale" : "Partita Iva"}</th>
                                            </tr>
                                            <tr>
                                                <td>{this.state.user.cellulare}</td>
                                                <td>{this.state.user.indirizzo}</td>
                                                <td>{this.state.user.tipoUtente == 1 ? this.state.user.codiceFiscale ? this.state.user.codiceFiscale : (
                                                    <span>Non Inserito</span>) : this.state.user.piva ? this.state.user.piva : (
                                                    <span>Non Inserita</span>)}</td>
                                            </tr>
                                            <tr>
                                                <th className={this.state.user.tipoUtente == 1 ? 'notDisplay' : ''}>{this.state.user.tipoUtente == 2 && this.state.user.nomeAzienda ? 'Azienda' : ""}</th>
                                                <th>Iban</th>
                                                <th>Username</th>
                                            </tr>
                                            <tr>
                                                <td className={this.state.user.tipoUtente == 1 ? 'notDisplay' : ''}>{this.state.user.tipoUtente == 2 && this.state.user.nomeAzienda ? this.state.user.nomeAzienda : ""}</td>
                                                <td>{this.state.user.iban == null ? (<span className={"myRedText"}>Da Inserire</span>) : this.state.user.iban}</td>
                                                <td>{this.state.user.username}</td>
                                            </tr>


                                        </table>

                                    </div>
                                </div>
                            </div>


                        </div>

                    )

                }

                <br/>
                <Dialog disableBackdropClick="true" open={this.state.openLogin} onClose={() => this.handleCloseLogin()}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description">
                    <DialogTitle id="alert-dialog-title">
                        <img width={140} src='../assets/imgs/appLogo.png' alt=""/><br/>
                        <br/>
                        <div onClick={() => this.setState({login: !this.state.login, recoverPwd: null})}
                             className={"myLoginText"}>{this.state.login ? "Non sei registrato? Registrati ora" : !this.state.login ? "Sei già registrato? Effettua il login" : this.state.recoverPwd ? "Recupera Password" : ""} >
                        </div>
                    </DialogTitle>
                    <form onSubmit={this.saveUser.bind(this)}>
                        <DialogContent>
                            {
                                this.state.login ?
                                    (
                                        <span>
                                            <h5>{this.state.recoverPwd ? "Inserisci la tua mail" : "Effettua il login"}</h5>
                                            <div className="myCustomModalPersonal">
                                            <FormControl className={"myFormWidth"}>
                                                    <label htmlFor="email">Email</label>
                                                    <input className={"form-control"} type="email" name="email"/>
                                                </FormControl>
                                                {
                                                    !this.state.recoverPwd &&
                                                    <FormControl className={"myFormWidth"} required>
                                                        <label htmlFor="password">Password</label>
                                                        <input className={"form-control"} type="password"
                                                               name="password"
                                                               required/>
                                                    </FormControl>
                                                }
                                        </div>
                                            {
                                                !this.state.recoverPwd &&
                                                <p className={"myRecoverButton"}
                                                   onClick={() => this.setState({recoverPwd: true})}>Non ricordi la
                                                    password? Clicca qui</p>
                                            }
                                        </span>
                                    ) :
                                    (<span>
                                        <div style={{alignItems: "center", marginRight: 0, marginLeft: 0}}
                                             className="row">
                                            <h5>Informazioni personali</h5>
                                            <span style={{width: 20}}></span>
                                             <span className={"myButtonChangeType"}
                                                   onClick={this.handleChangeAzienda}>{this.state.isPrivateUser ? "Sono un\'azienda" : "Sono un privato"}</span>

                                        </div>
                                        <div className="myCustomModalPersonal">
                                            <FormControl className={"halfWidth"} required>
                                                <label htmlFor="nome">Nome</label>
                                                <input className={"form-control"} type="text" name="nome" required/>
                                            </FormControl>
                                            <FormControl className={"halfWidth"} required>
                                                <label htmlFor="cognome">Cognome</label>
                                                <input className={"form-control"} type="text" name="cognome" required/>
                                            </FormControl>
                                            <FormControl className={"halfWidth"} required>
                                                <label htmlFor="username">Username</label>
                                                <input className={"form-control"} type="text" name="username" required/>
                                            </FormControl>
                                            <div style={{margin: 0}} className="row">
                                                <FormControl className={"halfWidth"} required>
                                                    <label htmlFor="email">Email</label>
                                                    <input className={"form-control"} type="email" name="email"
                                                           required/>
                                                </FormControl>
                                                <FormControl className={"halfWidth"} required>
                                                    <label htmlFor="cellulare">Cellulare</label>
                                                    <input className={"form-control"} type="tel" name="cellulare"
                                                           required/>
                                                </FormControl>
                                            </div>
                                             <div style={{margin: 0}} className="row">
                                                <FormControl className={"halfWidth"} required>
                                                    <label htmlFor="password">Password</label>
                                                    <input className={"form-control"}
                                                           type={this.state.hidePwd ? "password" : "text"}
                                                           name="password" required/>
                                                    {
                                                        this.state.hidePwd ?
                                                            <VisibilityIcon className={"myEyeButton"}
                                                                            onClick={() => this.showHidePwd()}/> :
                                                            <VisibilityOffIcon className={"myEyeButton"}
                                                                               onClick={() => this.showHidePwd()}/>
                                                    }

                                                </FormControl>
                                                 {this.state.isPrivateUser ?
                                                     (
                                                         <FormControl className={"halfWidth"}>
                                                             <label htmlFor="codiceFiscale">Codice Fiscale <span
                                                                 style={{
                                                                     fontSize: "10px",
                                                                     color: "grey"
                                                                 }}>Opzionale</span></label>
                                                             <input className={"form-control"} type="text"
                                                                    name="codiceFiscale"/>
                                                         </FormControl>
                                                     ) :
                                                     (
                                                         <FormControl className={"halfWidth"} required>
                                                             <label htmlFor="pIva">Partita Iva</label>
                                                             <input className={"form-control"} type="text" name="pIva"
                                                                    required/>
                                                         </FormControl>
                                                     )
                                                 }
                                                 {this.state.isPrivateUser ?
                                                     (
                                                         <span></span>
                                                     ) :
                                                     (
                                                         <FormControl className={"myFormWidth"} required>
                                                             <label htmlFor="azienda">Ragione Sociale</label>
                                                             <input className={"form-control"} type="text"
                                                                    name="azienda" required/>
                                                         </FormControl>
                                                     )
                                                 }
                                            </div>

                                        </div>
                                        <br/>
                                        <h5>Indirizzo ritiro</h5>
                                        <div className="myCustomModalAddress">
                                            <FormControl className={"halfWidth"} required>
                                                <label htmlFor="via">Via/Viale</label>
                                                <input className={"form-control"} type="text" name="via" required/>
                                            </FormControl>
                                            <FormControl className={"halfWidth"} required>
                                                <label htmlFor="numero">Numero civico</label>
                                                <input className={"form-control"} type="text" name="numero" required/>
                                            </FormControl>
                                            <FormControl className={"halfWidth"} required>
                                                <label htmlFor="citta">Città</label>
                                                <input className={"form-control"} type="text" name="citta" required/>
                                            </FormControl>
                                            <FormControl className={"halfWidth"} required>
                                                <label htmlFor="regione">Regione</label>
                                                <input className={"form-control"} type="text" name="regione" required/>
                                            </FormControl>
                                            <FormControl className={"halfWidth"} required>
                                                <label htmlFor="cap">CAP</label>
                                                <input className={"form-control"} type="text" name="cap" required/>
                                            </FormControl>
                                            <FormControl className={"halfWidth"} required>
                                                <label htmlFor="provincia">Provincia</label>
                                                <input className={"form-control"} type="text" name="provincia"
                                                       required/>
                                            </FormControl>

                                        </div>
                                    </span>)
                            }
                        </DialogContent>
                        <DialogActions style={{padding: 23}}>
                            {/*<button onClick={() => this.handleClose()}
                                    className="myButtonDisabled"> Annulla </button>*/}
                            <button style={{width: "100%"}} type="submit"
                                    color="primary"
                                    className={"myButton"}>
                                {this.state.recoverPwd ? "Recupera password" : this.state.login ? "Accedi" : "Registrati"}
                            </button>
                        </DialogActions>
                    </form>

                </Dialog>
                <Dialog open={this.state.open} onClose={() => this.handleClose()}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description">
                    <DialogTitle id="alert-dialog-title">
                        Modifica Informazioni
                        <button
                            style={{"color": "red", "font-size": 10}}
                            color="danger"
                            className={"myButtonText"}
                            onClick={() => this.setState({openElimina: true})}
                        >
                            Cancella Account
                        </button>
                    </DialogTitle>
                    <form onSubmit={this.updateUser.bind(this)}>
                        <DialogContent>

                            <div style={{alignItems: "center", marginRight: 0, marginLeft: 0}} className="row">
                                <h5>Informazioni personali</h5>
                                <span style={{width: 20}}></span>
                                <span className={"myButtonChangeType"}
                                      onClick={this.handleChangeAzienda}>{this.state.isPrivateUser ? "Sono un\'azienda" : "Sono un privato"}</span>
                            </div>
                            <div className="myCustomModalPersonal">
                                <FormControl className={"halfWidth"} required>
                                    <label htmlFor="nome">Nome</label>
                                    <input className={"form-control"} type="text" name="nome"
                                           defaultValue={this.state.user && this.state.user.nome} required/>
                                </FormControl>
                                <FormControl className={"halfWidth"} required>
                                    <label htmlFor="cognome">Cognome</label>
                                    <input className={"form-control"} type="text" name="cognome"
                                           defaultValue={this.state.user && this.state.user.cognome} required/>
                                </FormControl>
                                <FormControl className={"halfWidth"} required>
                                    <label htmlFor="username">Username</label>
                                    <input className={"form-control"} type="text" name="username"
                                           defaultValue={this.state.user && this.state.user.username} required/>
                                </FormControl>
                                <div style={{margin: 0}} className="row">
                                    <FormControl className={"halfWidth"} required>
                                        <label htmlFor="email">Email</label>
                                        <input className={"form-control"} type="email" name="email"
                                               defaultValue={this.state.user && this.state.user.email} required/>
                                    </FormControl>
                                    <FormControl className={"halfWidth"} required>
                                        <label htmlFor="cellulare">Cellulare</label>
                                        <input className={"form-control"} type="text" name="cellulare"
                                               defaultValue={this.state.user && this.state.user.cellulare} required/>
                                    </FormControl>
                                </div>
                                <div style={{margin: 0}} className="row">
                                    <FormControl className={"halfWidth"}>
                                        <label htmlFor="iban">Iban</label>
                                        <input className={"form-control"} type="text" name="iban"
                                               defaultValue={this.state.user && this.state.user.iban}/>
                                    </FormControl>
                                    {this.state.isPrivateUser ?
                                        (
                                            <FormControl className={"halfWidth"}>
                                                <label htmlFor="codiceFiscale">Codice Fiscale <span
                                                    style={{fontSize: "10px", color: "grey"}}>Opzionale</span></label>
                                                <input className={"form-control"} type="text" name="codiceFiscale"
                                                       defaultValue={this.state.user && this.state.user.codiceFiscale}/>
                                            </FormControl>
                                        ) :
                                        (
                                            <FormControl className={"halfWidth"} required>
                                                <label htmlFor="pIva">Partita Iva</label>
                                                <input className={"form-control"} type="text" name="pIva" required
                                                       defaultValue={this.state.user && this.state.user.piva}/>
                                            </FormControl>
                                        )
                                    }
                                    {this.state.isPrivateUser ?
                                        (
                                            <span></span>
                                        ) :
                                        (
                                            <FormControl className={"myFormWidth"} required>
                                                <label htmlFor="azienda">Ragione Sociale</label>
                                                <input className={"form-control"} type="text" name="azienda" required
                                                       defaultValue={this.state.user && this.state.user.nomeAzienda}/>
                                            </FormControl>
                                        )
                                    }
                                </div>

                            </div>
                            <br/>
                            <h5>Indirizzo ritiro</h5>
                            <div className="myCustomModalAddress">
                                <FormControl className={"halfWidth"} required>
                                    <label htmlFor="via">Via/Viale</label>
                                    <input className={"form-control"} type="text" name="via"
                                           defaultValue={this.state.user && this.state.user.indirizzo} required/>
                                </FormControl>
                                <FormControl className={"halfWidth"} required>
                                    <label htmlFor="numero">Numero civico</label>
                                    <input className={"form-control"} type="text" name="numero"
                                           defaultValue={this.state.user && this.state.user.numero} required/>
                                </FormControl>
                                <FormControl className={"halfWidth"} required>
                                    <label htmlFor="citta">Città</label>
                                    <input className={"form-control"} type="text" name="citta"
                                           defaultValue={this.state.user && this.state.user.citta} required/>
                                </FormControl>
                                <FormControl className={"halfWidth"} required>
                                    <label htmlFor="regione">Regione</label>
                                    <input className={"form-control"} type="text" name="regione"
                                           defaultValue={this.state.user && this.state.user.regione} required/>
                                </FormControl>
                                <FormControl className={"halfWidth"} required>
                                    <label htmlFor="cap">CAP</label>
                                    <input className={"form-control"} type="text" name="cap"
                                           defaultValue={this.state.user && this.state.user.cap} required/>
                                </FormControl>
                                <FormControl className={"halfWidth"} required>
                                    <label htmlFor="provincia">Provincia</label>
                                    <input className={"form-control"} type="text" name="provincia"
                                           defaultValue={this.state.user && this.state.user.provincia} required/>
                                </FormControl>
                            </div>
                        </DialogContent>
                        <DialogActions style={{padding: 23}}>
                            <button style={{width: "100%"}} type="submit"
                                    color="primary"
                                    className={"myButton"}>
                                Modifica
                            </button>
                        </DialogActions>
                    </form>

                </Dialog>
                <Dialog open={this.state.openIban} onClose={() => this.handleCloseIban()}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description">
                    <DialogTitle id="alert-dialog-title">
                        Attenzione
                    </DialogTitle>
                    <form onSubmit={this.updateUserIban.bind(this)}>
                        <DialogContent>
                            <p>Inserisci l'iban del tuo conto o della tua carta in modo da ricevere il pagamento quando
                                il tuo telefono verrà acquistato.</p>
                            <div className="myCustomModalPersonal">
                                <FormControl className={"myFormWidth"}>
                                    <label htmlFor="iban">Iban</label>
                                    <input className={"form-control"} type="text" name="iban"/>
                                </FormControl>
                            </div>
                        </DialogContent>
                        <DialogActions style={{padding: 23}}>
                            <button style={{width: "100%"}} type="submit"
                                    color="primary"
                                    className={"myButton"}>
                                Aggiungi Iban
                            </button>
                        </DialogActions>
                    </form>

                </Dialog>
                <Dialog open={this.state.openPrice} onClose={() => this.handleClosePrice()}
                        maxWidth={"lg"}
                        fullWidth={true}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description">
                    <DialogTitle id="alert-dialog-title">
                        Modifica Annuncio
                    </DialogTitle>
                    <form onSubmit={this.updatePrice.bind(this)}>
                        <DialogContent>
                            <div className="row">
                                <div className="col-sm">
                                    <Dashboard
                                        open={true}
                                        uppy={uppy}
                                        onRequestClose={this.handleCloseModal.bind(this)}
                                        height={400}
                                        width={"90%"}
                                        note={'Carica fino a 6 immagini, dimensione massima consentita 700kb'}
                                        disableInformer={false}
                                        proudlyDisplayPoweredByUppy={false}
                                        showProgressDetails={false}
                                        disableStatusBar={true}
                                    />
                                </div>
                                <div className="col-sm">
                                    <p>Modifica il prezzo di vendita del tuo dispositivo</p>
                                    <div className="myCustomModalPersonal">
                                        <FormControl className={"myFormWidth"} required>
                                            <label htmlFor="iban">Prezzo</label>
                                            <OutlinedInput
                                                style={{backgroundColor: "white"}}
                                                startAdornment={<InputAdornment position="start">€</InputAdornment>}
                                                type="text" name="prezzo"
                                                defaultValue={this.state.toModify && this.state.toModify.prezzo}
                                                required/>
                                        </FormControl>
                                        <FormControl className={"myFormWidth"} required>
                                            <label htmlFor="descrizioneRichiesta">Descrizione</label>
                                            <textarea
                                                style={{backgroundColor: "white"}}
                                                rows={4}
                                                type="text" name="descrizioneRichiesta"
                                                defaultValue={this.state.toModify && this.state.toModify.descrizioneRichiesta}
                                                required/>
                                        </FormControl>
                                    </div>
                                    <div style={{marginTop: 25, marginBottom: 25}}>
                                        <button style={{width: "100%"}} type="submit"
                                                color="primary"
                                                className={"myButton"}>
                                            Modifica
                                        </button>
                                    </div>
                                </div>

                            </div>
                        </DialogContent>
                    </form>

                </Dialog>
                <Dialog open={this.state.openModPwd} onClose={() => this.handleClosePwd()}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description">
                    <DialogTitle id="alert-dialog-title">
                        Modifica ora la tua password
                    </DialogTitle>
                    <form onSubmit={this.changePwd.bind(this)}>
                        <DialogContent>
                            <FormControl className={"myFormWidth"} required>
                                <label htmlFor="pwdOld">Vecchia Password</label>
                                <OutlinedInput
                                    style={{backgroundColor: "white"}}
                                    type="text" name="pwdOld"
                                    required/>
                            </FormControl>
                            <FormControl className={"myFormWidth"} required>
                                <label htmlFor="pwdNew">Nuova Password</label>
                                <OutlinedInput
                                    style={{backgroundColor: "white"}}
                                    type="text" name="pwdNew"
                                    required/>
                            </FormControl>
                        </DialogContent>
                        <DialogActions style={{padding: 23}}>
                            <button style={{width: "100%"}} type="submit"
                                    color="primary"
                                    className={"myButton"}>
                                Modifica
                            </button>
                        </DialogActions>
                    </form>

                </Dialog>

                <Dialog open={this.state.openReplyToMessage} onClose={() => this.handleCloseReplyToMessage()}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description">
                    <DialogTitle id="alert-dialog-title">
                        Rispondi al messaggio
                        <button style={{marginLeft: 2}} type="button" className="close" onClick={() => this.handleCloseReplyToMessage()}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </DialogTitle>
                    <form onSubmit={this.sendReplyToMessage.bind(this)}>
                        <DialogContent>
                            <TextField
                                id="msg"
                                label="Risposta"
                                multiline
                                rows={4}
                                name="msg"
                            />
                        </DialogContent>
                        <DialogActions style={{padding: 23}}>
                            <button style={{width: "100%"}}
                                    color="primary"
                                    className={"myButton"}>
                                Invia
                            </button>
                        </DialogActions>
                    </form>

                </Dialog>

                <Dialog open={this.state.openReActiveRichiesta} onClose={() => this.handleCloseReOpenRichiesta()}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description">
                    <DialogTitle id="alert-dialog-title">
                        Ripubblica il tuo annuncio
                    </DialogTitle>
                    <form onSubmit={this.republishRichiesta.bind(this)}>
                        <DialogContent>
                            <FormControl fullWidth>
                                <label id="dayForRemoveLabel">Durata Annuncio</label>
                                <Select
                                    labelId="dayForRemoveLabel"
                                    id="dayForRemove"
                                    name="dayForRemove"
                                    defaultValue={4}
                                >
                                    <MenuItem value={4}>4 Giorni</MenuItem>
                                    <MenuItem value={10}>10 Giorni</MenuItem>
                                    <MenuItem value={15}>15 Giorni</MenuItem>
                                    <MenuItem value={20}>20 Giorni</MenuItem>
                                </Select>
                                <label htmlFor="iban">Prezzo</label>
                                <OutlinedInput
                                    style={{backgroundColor: "white"}}
                                    id="prezzoToEdit"
                                    startAdornment={<InputAdornment position="start">€</InputAdornment>}
                                    type="text"
                                    name="prezzoToEdit"
                                    defaultValue={this.state.toModify && this.state.toModify.prezzo}
                                    required/>
                            </FormControl>
                        </DialogContent>
                        <DialogActions style={{padding: 23}}>
                            <button style={{width: "100%"}}
                                    color="primary"
                                    className={"myButton"}
                                    type="submit">
                                Ripubblica
                            </button>
                        </DialogActions>
                    </form>

                </Dialog>

                <Dialog open={this.state.openElimina} onClose={() => this.handleDeleteAccount()}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description">
                    <DialogTitle id="alert-dialog-title">
                        Sei sicuro di voler eliminare il tuo account?
                    </DialogTitle>
                    <form onSubmit={this.deleteAccount.bind(this)}>
                        <DialogContent>
                            <p>Una volta eliminato l'account non sarà più possible accedere alla piattaforma con le tue
                                credenziali.</p>
                            <FormControl className={"myFormWidth"} required>
                                <label htmlFor="pwdDelete">Inserisci la tua Password per eliminare l'account</label>
                                <OutlinedInput
                                    style={{backgroundColor: "white"}}
                                    type="text" name="pwdDelete"
                                    required/>
                            </FormControl>
                        </DialogContent>
                        <DialogActions style={{padding: 23}}>
                            <button style={{width: "100%"}}
                                    type={"submit"}
                                    color="danger"
                                    className={"deleteButton2"}>
                                Elimina
                            </button>
                        </DialogActions>
                    </form>

                </Dialog>

                <Dialog open={this.state.openOfferta} onClose={() => this.handleCloseOfferta()}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description">
                    <DialogTitle id="alert-dialog-title">
                        Nuova Messaggio
                        <button type="button" className="close" onClick={() => this.handleCloseOfferta()}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </DialogTitle>
                    {
                        this.state.messaggioOfferente ? <DialogContent>
                            Messaggio da parte dell'utente:
                            <p style={{fontWeight: 'bold'}}>{this.state.messaggioOfferente}</p>
                            <DialogActions>
                                <button variant="contained" type="button"
                                        style={{
                                            backgroundColor: "green"
                                        }}
                                        onClick={() => this.handleReplyToMessage()}
                                        disabled={this.state.messaggioOfferente == "" ? true : false}
                                        className={"myButton"}>
                                    Rispondi al messaggio
                                </button>
                            </DialogActions>
                        </DialogContent> : ''
                    }

                    {this.state.prezzo > 0.00 ? <DialogContent>
                        <p>Hai ricevuto un'offerta di € <span style={{fontWeight: 900}}>{this.state.prezzo}</span> <br/>Vuoi
                            accettare quest'offerta?<br/></p>
                        <Button style={{color: "rgb(197 197 197)", fontSize: 12}}
                                variant="text" type="button"
                                color="primary"
                                onClick={() => this.handleCloseOfferta()}
                        >
                            Non ora
                        </Button>
                        <button variant="contained" type="button"
                                style={{
                                    backgroundColor: "#de0202"
                                }}
                                onClick={() => {
                                    this.handleRejectOfferta()
                                }}
                                className={"myButton"}>
                            Rifiuta
                        </button>
                        <button variant="contained" type="button"
                                color="primary"
                                onClick={() => {
                                    this.handleAcceptOfferta()
                                }}
                                className={"myButton"}>
                            Accetta
                        </button>
                    </DialogContent> : ''
                    }

                </Dialog>
                <Dialog open={this.state.openDelete} onClose={() => this.handleCloseDelete()}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description">
                    <DialogTitle id="alert-dialog-title">
                        Attenzione
                    </DialogTitle>
                    <DialogContent>
                        <p>Vuoi eliminare l'annuncio {this.state.toDelete?.nomeProdotto} ?</p>
                    </DialogContent>
                    <DialogActions>
                        <button variant="contained" type="button"
                                onClick={() => {
                                    this.handleCloseDelete()
                                }}
                                className={"myButtonSecondaryOutlined"}>
                            Annulla
                        </button>
                        <button variant="contained" type="button"
                                color="primary"
                                style={{
                                    backgroundColor: "#de0202"
                                }}
                                onClick={() => {
                                    this.deleteAnnuncio()
                                }}
                                className={"myButton"}>
                            Elimina
                        </button>
                    </DialogActions>

                </Dialog>
            </div>
        );
    }

    updatedToast = () => toast.success("✔️ Utente Modificato con Successo !", {
        position: toast.POSITION.TOP_RIGHT,
    });
    delteteToast = () => toast.success("✔️ Annuncio Eliminato con Successo !", {
        position: toast.POSITION.TOP_RIGHT,
    });
    utenteEliminato = () => toast.success("✔️ Il tuo account è stato eliminato con successo !", {
        position: toast.POSITION.TOP_RIGHT,
    });
}
