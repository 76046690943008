import {Component} from "react";
import Loader from "../components/Loader/loader";
import api from "../api";

export default class Offerta extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            isError: false
        };
    }

    componentDidMount() {
        const query = new URLSearchParams(this.props.location.search);
        const sku = query.get('sku')
        const email = query.get('email')
        const name = query.get('name')
        const price = query.get('price')
        const val = query.get('val')
        const id = query.get('id')
        const textMessage= query.get('textMessage')
        this.sendShopify(sku, email, name, price, val, id, textMessage);
    }

    sendShopify(sku, email, name, price, val, id, textMessage) {
        let bodyNew = {
            sku,
            nome: name,
            prezzo: price,
            email,
            val,
            id,
            textMessage
        };
        api.post('offerte/sendOffertaShopify',bodyNew )
            .then((response) => {
                this.setState({
                    isLoading: false
                })
            })
            .catch(error => {
                this.setState({
                    isError: true
                })
                console.log(error);
            })
    }

    render() {
        const {isLoading, isError} = this.state;
        return (
            <div>
                {
                    isLoading ? <Loader></Loader> :
                        isError ? <div className="card">
                            <div style={{
                                borderRadius: "200px",
                                height: "200px",
                                width: "200px",
                                background: "#ffcfcf",
                                margin: "0 auto"
                            }}>
                                <i style={{color: "#fd4444",fontStyle:"initial",fontSize: 160, marginLeft: 0}} className="checkmark">X</i>
                            </div>
                                <h1>Oops!</h1>
                                <p>Qualcosa è andato storto<br/>non è possibile inviare un'offerta per questo dispositivo<br/></p>
                            </div>
                            : <div className="card">
                            <div style={{
                                borderRadius: "200px",
                                height: "200px",
                                width: "200px",
                                background: "#ecffcf",
                                margin: "0 auto"
                            }}>
                                <i className="checkmark">✓</i>
                            </div>
                                <h1>Ben Fatto!</h1>
                                <p>Il tuo messaggio è stato inviato con successo<br/>riceverai aggiornamenti via mail<br/></p>
                        </div>
                }
            </div>
        );
    }
}
