import React, {Component} from "react";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../components/Loader/loader";

export default class AccountEliminato extends Component {
    constructor() {
        super();
        this.state = {
            isLoading: false,
            isError: false
        };
    }
    redirectToHomePage(){
        localStorage.clear()
        this.props.history.push('/');
    }
    componentDidMount() {

    }

    render() {
        const {isLoading, isError} = this.state;
        setTimeout(() => this.redirectToHomePage(),3000)
        return (
            <div>
                {
                     <Loader text={true}>
                     </Loader>
                }
            </div>
        )
    }
}