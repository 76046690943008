import React, {Component} from "react";
import HorizontalLinearStepper from "../components/stepper";
import FormControl from "@material-ui/core/FormControl";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import "react-toastify/dist/ReactToastify.css";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import {Link} from "react-router-dom";
import api from "../api/index";
import {CircularProgress, Tooltip} from "@material-ui/core";
import ExitIcon from "@material-ui/icons/ExitToApp";
import {toast, ToastContainer} from "react-toastify";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
const Uppy = require('@uppy/core')
const Italian = require('@uppy/locales/lib/it_IT') // see below for the full list of locales

export default class Seller extends Component {

    constructor(props) {
        super(props);
        this.state = {
            usuraSelected: -1,
            usuraSchermoSelected: -1,
            usuraTasti: -1,
            descrizione: "",
            isLoading: false,
            isPrivateUser: true,
            login: false,
            hidePwd: true,
            prezzoVendita: null,
            sizeSelected: "",
            modelSelected: "",
            colorSelected: "",
            produttoreSelected: null,
            iphoneExtra: [
                {
                    "domanda": "In che stato si trova la fotocamera del tuo dipositivo?",
                    "type": "1",
                    "label": "Fotocamera",
                    "key":"fotocamera",
                    "risposte":[
                        "Perfetta",
                        "Con Problemi",
                        "Danneggiata"
                    ]
                },
                {
                    "domanda": "In che stato si trova il touch screen del tuo dipositivo?",
                    "type": "1",
                    "label": "Touch Screen",
                    "key":"touchScreen",
                    "risposte":[
                        "Perfetto",
                        "Con Problemi",
                        "Danneggiato"
                    ]
                },
                {
                    "domanda": "In che stato si trova la batteria del tuo dipositivo?",
                    "type": "1",
                    "label": "Stato Batteria",
                    "key":"statoBatteria",
                    "risposte":[
                        "Perfetto Stato",
                        "Normale usura",
                        "Scarso stato",
                        "Danneggiata"
                    ]
                },
                {
                    "domanda": "Hai la scatola originale? Se sì in che condizioni si trova?",
                    "finalQuestions": true,
                    "type": "1",
                    "label": "Condizioni scatola",
                    "key":"scatola",
                    "risposte":[
                        "Perfetto Stato",
                        "Normale usura",
                        "Scarso stato",
                        "Non presente"
                    ]
                },
                {
                    "domanda": "Hai gli accessori originali? In che condizioni ?",
                    "finalQuestions": true,
                    "type": "1",
                    "label": "Condizioni accessori",
                    "key":"accessori",
                    "risposte":[
                        "Perfetto Stato",
                        "Normale usura",
                        "Scarso stato",
                        "Non presenti"
                    ]
                }
            ],
            ipadExtra: [
                {
                    "domanda": "In che stato si trova la fotocamera del tuo dipositivo?",
                    "type": "1",
                    "label": "Fotocamera",
                    "key":"fotocamera",
                    "risposte":[
                        "Perfetta",
                        "Con Problemi",
                        "Danneggiata"
                    ]
                },
                {
                    "domanda": "In che stato si trova il touch screen del tuo dipositivo?",
                    "type": "1",
                    "label": "Touch Screen",
                    "key":"touchScreen",
                    "risposte":[
                        "Perfetto",
                        "Con Problemi",
                        "Danneggiato"
                    ]
                },
                {
                    "domanda": "In che stato si trova la batteria del tuo dipositivo?",
                    "type": "1",
                    "label": "Stato Batteria",
                    "key":"statoBatteria",
                    "risposte":[
                        "Perfetto Stato",
                        "Normale usura",
                        "Scarso stato",
                        "Danneggiata"
                    ]
                },
                {
                    "domanda": "Che tipo di connettività ha il tuo dispositivo?",
                    "type": "1",
                    "label": "Connettività",
                    "key":"connetivita",
                    "risposte":[
                        "Wi-fi",
                        "Cellular"
                    ]
                },
                {
                    "domanda": "Qual è l'anno di produzione del tuo dispositivo ? ",
                    "type": "2",
                    "label": "Anno Produzione",
                    "key":"annoProduzione",
                    "risposte":[]
                },
                {
                    "domanda": "Hai la scatola originale? Se sì in che condizioni si trova?",
                    "finalQuestions": true,
                    "type": "1",
                    "label": "Condizioni scatola",
                    "key":"scatola",
                    "risposte":[
                        "Perfetto Stato",
                        "Normale usura",
                        "Scarso stato",
                        "Non presente"
                    ]
                },
                {
                    "domanda": "Hai gli accessori originali? In che condizioni ?",
                    "finalQuestions": true,
                    "type": "1",
                    "label": "Condizioni accessori",
                    "key":"accessori",
                    "risposte":[
                        "Perfetto Stato",
                        "Normale usura",
                        "Scarso stato",
                        "Non presenti"
                    ]
                }
            ],
            macExtra: [
                {
                    "domanda": "In che stato si trova la batteria del tuo dipositivo?",
                    "type": "1",
                    "label": "Stato Batteria",
                    "key":"statoBatteria",
                    "risposte":[
                        "Perfetto Stato",
                        "Normale usura",
                        "Scarso stato",
                        "Danneggiata"
                    ]
                },
                {
                    "domanda": "Qual è il processore del tuo Mac ? ",
                    "type": "2",
                    "label": "Processore",
                    "key":"processore",
                    "risposte":[]
                },
                {
                    "domanda": "Qual è l'anno di produzione del tuo Mac ? ",
                    "type": "2",
                    "label": "Anno Produzione",
                    "key":"annoProduzione",
                    "risposte":[]
                },
                {
                    "domanda": "Quanta ram ha il tuo Mac ",
                    "type": "2",
                    "label": "Ram",
                    "key":"ram",
                    "risposte":[]
                },
                {
                    "domanda": "Hai la scatola originale? Se sì in che condizioni si trova?",
                    "finalQuestions": true,
                    "type": "1",
                    "label": "Condizioni scatola",
                    "key":"scatola",
                    "risposte":[
                        "Perfetto Stato",
                        "Normale usura",
                        "Scarso stato",
                        "Non presente"
                    ]
                },
                {
                    "domanda": "Hai gli accessori originali? In che condizioni ?",
                    "finalQuestions": true,
                    "type": "1",
                    "label": "Condizioni accessori",
                    "key":"accessori",
                    "risposte":[
                        "Perfetto Stato",
                        "Normale usura",
                        "Scarso stato",
                        "Non presenti"
                    ]
                }
            ],
            appleTvExtra: [{
                "domanda": "Hai la scatola originale? Se sì in che condizioni si trova?",
                "finalQuestions": true,
                "type": "1",
                "label": "Condizioni scatola",
                "key":"scatola",
                "risposte":[
                    "Perfetto Stato",
                    "Normale usura",
                    "Scarso stato",
                    "Non presente"
                ]
            },
                {
                    "domanda": "Hai gli accessori originali? In che condizioni ?",
                    "finalQuestions": true,
                    "type": "1",
                    "label": "Condizioni accessori",
                    "key":"accessori",
                    "risposte":[
                        "Perfetto Stato",
                        "Normale usura",
                        "Scarso stato",
                        "Non presenti"
                    ]
                }],
            appleWatchExtra: [{
                "domanda": "Hai la scatola originale? Se sì in che condizioni si trova?",
                "finalQuestions": true,
                "type": "1",
                "label": "Condizioni scatola",
                "key":"scatola",
                "risposte":[
                    "Perfetto Stato",
                    "Normale usura",
                    "Scarso stato",
                    "Non presente"
                ]
            },
                {
                    "domanda": "Hai gli accessori originali? In che condizioni ?",
                    "finalQuestions": true,
                    "type": "1",
                    "label": "Condizioni accessori",
                    "key":"accessori",
                    "risposte":[
                        "Perfetto Stato",
                        "Normale usura",
                        "Scarso stato",
                        "Non presenti"
                    ]
                }],
            airPodsExtra: [{
                "domanda": "Hai la scatola originale? Se sì in che condizioni si trova?",
                "finalQuestions": true,
                "type": "1",
                "label": "Condizioni scatola",
                "key":"scatola",
                "risposte":[
                    "Perfetto Stato",
                    "Normale usura",
                    "Scarso stato",
                    "Non presente"
                ]
            },
                {
                    "domanda": "Hai gli accessori originali? In che condizioni ?",
                    "finalQuestions": true,
                    "type": "1",
                    "label": "Condizioni accessori",
                    "key":"accessori",
                    "risposte":[
                        "Perfetto Stato",
                        "Normale usura",
                        "Scarso stato",
                        "Non presenti"
                    ]
                }],
            playstationExtra: [],
            modelli: [],
            arrAnswer: [],
            images: [],
            openWarning: false,
            colori: [
                {name: "Aluminium"},
                {name: "Rose Gold"},
                {name: "Gold"},
                {name: "Silver"},
                {name: "Space Grey"},
                {name: "Midnight Green"},
                {name: "Red"},
                {name: "Coral"},
                {name: "Yellow"},
                {name: "Light Green"},
                {name: "Light Yellow"},
                {name: "Light Purple"},
                {name: "Blue"},
                {name: "White"},
                {name: "Black"},
            ],
            dataReady: false,
            storage: [
                {name: "16 GB"},
                {name: "32 GB"},
                {name: "64 GB"},
                {name: "128 GB"},
                {name: "256 GB"},
                {name: "512 GB"}
            ]
        };
    }

    updateMyPezzotto() {
        this.setState({
            tmp: Math.random()+10000
        })
    };

    async componentDidMount() {
        this.getProdotti();
        this.getProduttori();
        const uppy = new Uppy({
            locale: Italian,
            restrictions: {
                maxFileSize: 1440000,
                maxNumberOfFiles: 6,
                minNumberOfFiles: 1,
                allowedFileTypes: ['image/*']
            },
        });
        this.setState({
            uppy
        })
        uppy.on('file-added',  () => {
            this.updateMyPezzotto();
        });
        uppy.on('file-removed',  () => {
            this.updateMyPezzotto();
        });
        if(localStorage.getItem('user')){
            this.setState({
                user: JSON.parse(localStorage.getItem('user'))
            })
        }
    }


    errorToast = () => toast.error("❌️ Le credenziali non sono valide!", {
        position: toast.POSITION.TOP_RIGHT,
    });
    errorToast2 = () => toast.error("❌️ La password inserita non è valida!", {
        position: toast.POSITION.TOP_RIGHT,
    });

    errorToastRegister = () => toast.error("❌️ La mail inserita è già registrata!", {
        position: toast.POSITION.TOP_RIGHT,
    });

    errorToastUsername = () => toast.error("❌️ L'username da te scelto è già in uso!", {
        position: toast.POSITION.TOP_RIGHT,
    });

    errorToastRecover = () => toast.error("❌️ La mail inserita non è registrata!", {
        position: toast.POSITION.TOP_RIGHT,
    });

    successAcceptRecoverToast = () => toast.success("✔️ Email inviata con successo!", {
        position: toast.POSITION.TOP_RIGHT,
    });

    getProdotti() {
        this.setState({
            dataReady: false
        });

        api.get('prodotti/getAll')
            .then((response) => {
                this.setState({
                    modelli: response.data.prodotti,
                    modelliComplete: response.data.prodotti
                })

            })
            .catch(error => {
                console.log(error);
            })
    }

    getProduttori() {

        api.get('prodotti/getAllProduttori')
            .then((response) => {
                const tmpProduttori = response.data?.produttori;
                const idFirst = response.data?.produttori[0]?.idProduttore;
                const tmpModelli = this.state.modelliComplete?.filter((elm) => elm['idProduttore'] == idFirst);
                this.setState({
                    produttori: response.data.produttori,
                    produttoreSelected: idFirst,
                    modelli: tmpModelli,

                })

            })
            .catch(error => {
                console.log(error);
            })
    }


    printHere = () => {
        this.setState({
            openWarning: true
        })
    }

    saveAdditionalData = (objectToSave) =>  {
        if(objectToSave['key'] == "descrizione") {
            this.setState({
                descrizione: objectToSave['value']
            })
        }
       let tmp = this.state.additionalAnswer;
       console.log(objectToSave);
       if(tmp) {
           let foundIndex = tmp.findIndex((elm) => elm['key'] == objectToSave['key']);
           if(foundIndex != -1) {
               tmp[foundIndex] = objectToSave;
               this.setState({
                   additionalAnswer: tmp
               })
           } else {
               tmp.push(objectToSave);
               this.setState({
                   additionalAnswer: tmp
               })
           }

       } else {
           tmp = [];
           tmp.push(objectToSave);
           this.setState({
               additionalAnswer: tmp
           })
       }
    }

    saveProduttoreSelected  = (event) =>  {
        console.log(this.state.modelli);
        let tmp = this.state.modelliComplete?.filter((elm) => elm['idProduttore'] == event.target.value);
        if(event.target.value != this.state.produttoreSelected) {
            this.setState({
                arrAnswer: [],
                modelSelected: "",
                additionalAnswer: null
            })
        }
        this.setState({
            modelli: tmp,
            produttoreSelected: event.target.value
        })


    }

    saveImagesInState = (arrImg) => {
        this.setState({
            images:arrImg
        })
        console.log(this.state.images);
    }

    saveUser = (event) =>  {
        event.preventDefault();
        if(this.state.recoverPwd) {
            let body = {
                email: event.target.email.value
            };
            api.post('auth/resetPwd',body)
                .then((response) => {
                    this.setState({
                        recoverPwd: false,
                    },() => {
                        this.successAcceptRecoverToast();
                    });
                })
                .catch(error => {
                    this.errorToastRecover();
                    console.log(error);
                })
        } else {
            this.setState({
                isLoading: true,
                open: false
            })
            if (this.state.login) {
                let body = {
                    email: event.target.email.value,
                    pwd: event.target.password.value
                };
                api.post('auth/login', body)
                    .then((response) => {
                        if (response.status == 401) {

                        }
                        this.setState({
                            user: response.data.user
                        })
                        localStorage.setItem('user', JSON.stringify(response.data.user));
                        if (localStorage.getItem('idRichiesta') != null && localStorage.getItem('idRichiesta') != "null") {
                            let bodyNew = {
                                "idRichiesta": localStorage.getItem('idRichiesta'),
                                "img" : JSON.parse(localStorage.getItem('imgBase64'))
                            };
                            api.post('richieste/addUtenteRichiesta', bodyNew, {
                                headers: {
                                    Authorization: response.data.user.token
                                }
                            })
                                .then((response) => {
                                    localStorage.setItem('idRichiesta', null)
                                    localStorage.removeItem('arleadyRequested')
                                    this.setState({
                                        isLoading: false
                                    })
                                    if (this.state.fromButton) {
                                        this.setState({
                                            open: false
                                        })
                                    } else {
                                        this.props.history.push('/profilo')
                                    }
                                })
                                .catch(error => {
                                    if (this.state.fromButton) {
                                        this.setState({
                                            open: false
                                        })
                                    } else {
                                        this.props.history.push('/profilo')
                                    }
                                    console.log(error);
                                })
                        } else {
                            this.setState({
                                isLoading: false
                            })
                            if (this.state.fromButton) {
                                this.setState({
                                    open: false
                                })
                            } else {
                                this.props.history.push('/profilo')
                            }
                        }
                    })
                    .catch(error => {
                        if (error.response.status == 401) {
                            this.errorToast2();
                            this.setState({
                                isLoading: false
                            })
                        } else if (error.response.status == 404) {
                            this.errorToast();
                            this.setState({
                                isLoading: false
                            })
                        } else {
                            this.errorToast();
                            this.setState({
                                isLoading: false
                            })
                        }

                        console.log(error);
                    })
            } else {
                let body = {
                    nome: event.target.nome.value,
                    cognome: event.target.cognome.value,
                    email: event.target.email.value,
                    cellulare: event.target.cellulare.value,
                    indirizzo: event.target.via.value,
                    citta: event.target.citta.value,
                    pwd: event.target.password.value,
                    numero: event.target.numero.value,
                    regione: event.target.regione.value,
                    provincia: event.target.provincia.value,
                    tipoUtente: this.state.isPrivateUser ? 1 : 2,
                    codiceFiscale: this.state.isPrivateUser ? event.target.codiceFiscale.value : null,
                    piva: !this.state.isPrivateUser ? event.target.pIva.value : null,
                    nomeAzienda: !this.state.isPrivateUser ? event.target.azienda.value : null,
                    cap: event.target.cap.value,
                    username: event.target.username.value
                };
                api.post('auth/registrati', body)
                    .then((response) => {
                        this.setState({
                            user: response.data.user
                        })
                        localStorage.setItem('user', JSON.stringify(response.data.user));
                        if (localStorage.getItem('idRichiesta') != null && localStorage.getItem('idRichiesta') != "null") {
                            let bodyNew = {
                                "idRichiesta": localStorage.getItem('idRichiesta'),
                                "img" : JSON.parse(localStorage.getItem('imgBase64'))
                            };
                            api.post('richieste/addUtenteRichiesta', bodyNew, {
                                headers: {
                                    Authorization: response.data.user.token
                                }
                            })
                                .then((response) => {
                                    localStorage.setItem('idRichiesta', null)
                                    localStorage.removeItem('arleadyRequested')
                                    this.setState({
                                        isLoading: false
                                    })
                                    window.gtag('config', 'AW-350172558');
                                    this.props.history.push('/profilo')
                                })
                                .catch(error => {
                                    this.props.history.push('/profilo')
                                    console.log(error);
                                })
                        } else {
                            this.setState({
                                isLoading: false
                            })
                            window.gtag('config', 'AW-350172558');
                            this.props.history.push('/profilo')
                        }
                    })
                    .catch(error => {
                        if(error.response.status == 400)
                        {
                            this.errorToastRegister();
                            this.setState({
                                isLoading: false
                            })
                        }
                        else if(error.response.status == 409)
                        {
                            this.errorToastUsername();
                            this.setState({
                                isLoading: false,
                            })
                        }

                        console.log(error);
                    })
            }
        }
    }

    toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

    async saveToDb() {
        this.setState({
            openWarning: false,
            isLoading: true
        })
        let body = {
            "prezzo" : this.state.prezzoVendita,
            "idProdotto": this.state.modelSelected.idProdotto,
            "risposte": this.state.arrAnswer
        }
        this.state.additionalAnswer.forEach((elm) => {
            body[elm.key] = elm.value;
        });
        var imgBase64 = [];

        for (const [key, value] of Object.entries(this.state.uppy?.state?.files)) {
            const result = await this.toBase64(value.data);
            imgBase64.push(result);
        }
        localStorage.setItem('imgBase64',JSON.stringify(imgBase64));

        api.post('richieste/add',body)
            .then((response) => {
                localStorage.setItem('arleadyRequested', "1")
                localStorage.setItem('idRichiesta', response.data.idRichiesta)
                if(localStorage.getItem('user')){
                    let bodyNew = {
                        "idRichiesta": response.data.idRichiesta,
                        "img" : imgBase64
                    };
                    api.post('richieste/addUtenteRichiesta',bodyNew, {
                        headers: {
                            Authorization: JSON.parse(localStorage.getItem('user')).token
                        }})
                        .then((response) => {
                            localStorage.setItem('idRichiesta', null)
                            localStorage.removeItem('arleadyRequested')
                            this.setState({
                                isLoading: false
                            })
                            window.gtag('config', 'AW-350172558');
                            this.props.history.push('/profilo')
                        })
                        .catch(error => {
                            this.props.history.push('/profilo')
                            console.log(error);
                        })
                } else{
                    this.setState({
                        open: true,
                    });
                }
            })
            .catch(error => {
                console.log(error);
            })
    }

    saveModelSelected = (event) =>  {
        var result = this.state.modelli.find(obj => {
            return obj.idProdotto == event.target.value
        })
        let body = {
            idProdotto: event.target.value
        }
        api.post('prodotti/getMedia', body)
            .then((response) => {
                console.log(response.data);
                this.setState({
                    modelSelected: result,
                    arrAnswer: [],
                    additionalAnswer: null,
                    mediaPrezzo: response.data?.prezzoMedio
                })
            })
            .catch(error => {
                console.log(error);
            })


        this.getDomandeByProdotto(result);
    }

    getDomandeByProdotto(obj) {
        let body = {
            "tipoDomanda" : obj.tipoDomande
        }
        api.post('domande/getDomandeByProdotto',body)
            .then((response) => {
                console.log(response.data.domande);
                this.setState({
                    domande: response.data.domande
                })

            })
            .catch(error => {
                console.log(error);
            })
    }

    saveColorSelected = (event) =>  {
        this.setState({
            colorSelected: event.target.value
        })
    }

    saveSizeSelected = (event) =>  {
        this.setState({
            sizeSelected: event.target.value
        })
    }
    handleClose() {
        this.setState({
            open: false,
        });
    }

    saveAnswer = (event) => {
        let toAdd = true;
        let tmpArr = this.state.arrAnswer;
        let selected = JSON.parse(event.target.value);
        for(let i = 0; i < tmpArr.length; i++){
            if(selected.idDomanda == tmpArr[i].idDomanda) {
                tmpArr[i] = selected;
                toAdd = false;
            }
        }
        if(toAdd) {
            tmpArr.push(selected);
        }
        this.setState({
            arrAnswer: tmpArr
        })
    }

    saveUsuraSelected = (event) => {
        this.setState({
            usuraSelected: event.target.value
        })
    }
    saveTastiSelected = (event) => {
        this.setState({
            usuraTasti: event.target.value
        })
    }

    showHidePwd() {
        this.setState({
            hidePwd: !this.state.hidePwd
        })
    }

    handleCloseWarning() {
        this.setState({
            isLoading: false,
            openWarning: false
        })
    }

    handleSubmitOffer() {
        this.saveToDb()
    }

    handleChangeAzienda = (event) => {
        this.setState({
            isPrivateUser: !this.state.isPrivateUser
        })
    }

    saveUsuraSchermoSelected = (event) => {
        this.setState({
            usuraSchermoSelected: event.target.value
        })
    }

    checkIfFirstCouldProceed() {
        return this.state.modelSelected && this.state.colorSelected && this.state.sizeSelected &&
            this.state.modelSelected != ""  && this.state.colorSelected != "" && this.state.sizeSelected != "";
    }

     handleWarning = (event) => {
         this.setState({openWarning: false});
    }

    handlePrezzoChange = (event) => {
        this.setState({ prezzoVendita: event.target.value });
    };

    accedi() {
        this.setState({
            login: true,
            fromButton: true,
            open: true
        })
    }
    getArrayExtra() {
        if(this.state.modelSelected && this.state.modelSelected != "" && this.state.modelSelected.nomeProdotto.toLowerCase().includes('mac')) {
            return this.state.macExtra;
        } else if(this.state.modelSelected && this.state.modelSelected != "" && this.state.modelSelected.nomeProdotto.toLowerCase().includes('ipad')) {
            return this.state.ipadExtra;
        } else if (this.state.modelSelected && this.state.modelSelected != "" && this.state.modelSelected.nomeProdotto.toLowerCase().includes('apple tv')){
            return this.state.appleTvExtra;
        } else if (this.state.modelSelected && this.state.modelSelected != "" && this.state.modelSelected.nomeProdotto.toLowerCase().includes('apple watch')){
            return this.state.appleWatchExtra;
        } else if (this.state.modelSelected && this.state.modelSelected != "" && this.state.modelSelected.nomeProdotto.toLowerCase().includes('air pods')){
            return this.state.airPodsExtra;
        } else if(this.state.playstationExtra && this.state.modelSelected != "" && this.state.modelSelected.nomeProdotto.toLowerCase().includes('playstation')){
            return this.state.playstationExtra;
        }
        else {
            return this.state.iphoneExtra
        }
    }

    logout() {
        this.setState({
            user: null
        })
        localStorage.removeItem('user');
        this.props.history.push('/');
    }

    render() {
        return (
            <div className="blank-wrapper">
                <ToastContainer autoClose={3000}/>
                <img style={{padding: 20}} width={180} src='../../assets/imgs/appLogo.png' alt=""/>
                <span onClick={() => this.accedi()} className={this.state.user ? "notDisplay" : "myAccess"}>Accedi </span>
                <span onClick={() => this.logout()} style={{color: "black"}}  className={this.state.user ? "myAccess" : "notDisplay"}>  Esci <ExitIcon/> </span>
                <span style={{cursor: "pointer"}} onClick={() => this.props.history.push('/profilo')} className={this.state.user ? "myAccess" : "notDisplay"}>Vai al profilo</span>
                <HorizontalLinearStepper
                    firstCheck={this.checkIfFirstCouldProceed.bind(this)}
                    modello={this.state.modelSelected}
                    colore={this.state.colorSelected}
                    taglia={this.state.sizeSelected}
                    colori={this.state.colori}
                    modelli={this.state.modelli}
                    storage={this.state.storage}
                    callback={this.printHere.bind(this)}
                    valueUsura={this.state.usuraSelected}
                    valueUsuraSchermo={this.state.usuraSchermoSelected}
                    saveTasti={this.saveTastiSelected.bind(this)}
                    saveModelSelected={this.saveModelSelected.bind(this)}
                    saveColorSelected={this.saveColorSelected.bind(this)}
                    saveSizeSelected={this.saveSizeSelected.bind(this)}
                    saveUsura={this.saveUsuraSelected.bind(this)}
                    saveUsuraSchermo={this.saveUsuraSchermoSelected.bind(this)}
                    valueTasti={this.state.usuraTasti}
                    prezzo={this.state.prezzoVendita}
                    handlePrezzoChange={this.handlePrezzoChange.bind(this)}
                    domande={this.state.domande}
                    arrAnswer={this.state.arrAnswer}
                    saveAnswer={this.saveAnswer.bind(this)}
                    produttore={this.state.produttoreSelected}
                    produttori={this.state.produttori}
                    saveAdditionalData={this.saveAdditionalData.bind(this)}
                    saveProduttoreSelected={this.saveProduttoreSelected.bind(this)}
                    additionalAnswer={this.state.additionalAnswer}
                    extraArray={this.getArrayExtra()}
                    images={this.state.uppy?.state?.files}
                    uppy={this.state.uppy}
                    descrizione={this.state.descrizione}
                    prezzoMedia={this.state.mediaPrezzo}
                >

                </HorizontalLinearStepper>
                {
                    this.state.isLoading ? (
                            <div className="overlay">
                                <div className="overlay__inner">
                                    <div className="overlay__content"><span className="spinner"></span></div>
                                </div>
                            </div>
                    ) : (
                        <span></span>
                    )

                }

                <br/>
                <Dialog
                    maxWidth={"lg"}
                    fullWidth={"true"}
                    open={this.state.openWarning}
                    onClose={() => this.handleCloseWarning()}>
                    <DialogTitle id="alert-dialog-title">
                        Termini & Condizioni
                    </DialogTitle>
                    <DialogContent>
                        <div className="row">
                            <div className="col-sm">
                                <img width={"100%"} height={"100%"} src="https://cdn.shopify.com/s/files/1/0553/1336/7201/t/3/assets/pf-53d9d445--91.jpg?v=1626688078" alt=""/>
                            </div>
                            <div className="col-sm" style={{marginTop: 10}}>
                                <p>
                                    <h5>
                                        Validato.it si basa sulla fiducia reciproca tra utenti.
                                    </h5>
                                    Pubblicare un’annuncio significa prendere un’impegno importante verso la community di <b>Validato.it</b>. Se non sei sicuro al 100% di vendere il tuo device o se il device non è in tuo possesso, non procedere nella compilazione dell’annuncio.
                                    <br/>A seconda dell’opzione scelta dall’acquirente, dovrai spedire il device direttamente a lui oppure al nostro laboratorio per la Validazione. <br/>
                                    Alcuni acquirenti possono richiedere la “Validazione”, significa che il tuo device farà una tappa intermedia nel nostro laboratorio. Verificheremo che quanto dichiarato nell’annuncio corrisponda alle reali condizioni del prodotto e apporremo una garanzia di 3 mesi al prodotto prima di spedirlo all’acquirente finale.
                                    <br/>Eventuali difformità rilevate in sede di validazione, potrebbero comportare l’annullamento dell’ordine con l’addebito a tuo carico delle spese di restituzione e validazione del device. <br/>
                                    <br/><h5>Validato.it ti aiuta ad organizzare la spedizione. </h5>
                                    Indipendentemente dall’opzione scelta dall’acquirente, <span style={{fontWeight: "bold"}}>Validato.it</span> ti assiste nell’organizzare la spedizione. Concorderemo con te data e ora di ritiro del device da parte di uno dei nostri corriere espressi, gratuitamente.
                                    <br/> Elaboreremo il pagamento nei tuoi confronti 2 giorni dopo la ricezione del device da parte dell’acquirente.
                                    Se è tutto ok, la somma versata dall’acquirente al momento dell’ordine ti verrà trasferita a partire da 2 giorni dopo la ricezione. Vuoi essere pagato velocemente? <br/>Organizza la spedizione altrettanto velocemente.</p>
                            </div>
                        </div>

                    </DialogContent>
                    <DialogActions style={{padding: 10}}>
                        <button
                                onClick={() => {
                                    this.handleSubmitOffer();
                                }}
                                type="button"
                                color="primary"
                                className={"myButton"}>
                            Accetto e Continua
                        </button>
                    </DialogActions>
                </Dialog>
                <Dialog open={this.state.open} onClose={() => this.handleClose()}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description">
                    <DialogTitle id="alert-dialog-title">
                        <img width={140} src='../assets/imgs/appLogo.png' alt=""/><br/>
                        <br/><div onClick={() => this.setState({login: !this.state.login, recoverPwd : null})} className={"myLoginText"}>{this.state.login ? "Non sei registrato? Registrati ora"  : !this.state.login ? "Sei già registrato? Effettua il login": this.state.recoverPwd ? "Recupera Password" : ""} ></div>
                    </DialogTitle>
                    <form onSubmit={this.saveUser.bind(this)}>
                        <DialogContent>
                            {
                                this.state.login ?
                                    (
                                        <span>
                                            <h5>{this.state.recoverPwd ? "Inserisci la tua mail" : "Effettua il login"}</h5>
                                            <div className="myCustomModalPersonal">
                                            <FormControl className={"myFormWidth"}>
                                                    <label htmlFor="email">Email</label>
                                                    <input className={"form-control"} type="email" name="email"/>
                                                </FormControl>
                                                {
                                                    !this.state.recoverPwd &&
                                                    <FormControl className={"myFormWidth"} required>
                                                        <label htmlFor="password">Password</label>
                                                        <input className={"form-control"} type="password" name="password"
                                                               required/>
                                                    </FormControl>
                                                }
                                        </div>
                                            {
                                                !this.state.recoverPwd &&
                                                <p className={"myRecoverButton"}
                                                onClick={() => this.setState({recoverPwd: true})}>Non ricordi la
                                                password? Clicca qui</p>
                                            }
                                        </span>
                                    ):
                                    (<span>
                                        <div style={{alignItems: "center",marginRight: 0, marginLeft: 0}} className="row">
                                            <h5>Informazioni personali</h5>
                                            <span style={{width: 20}}></span>
                                             <span className={"myButtonChangeType"} onClick={this.handleChangeAzienda}>{this.state.isPrivateUser ? "Sono un\'azienda" : "Sono un privato"}</span>

                                        </div>
                                        <div className="myCustomModalPersonal">
                                            <FormControl className={"halfWidth"} required>
                                                <label htmlFor="nome">Nome</label>
                                                <input className={"form-control"} type="text" name="nome" required/>
                                            </FormControl>
                                            <FormControl className={"halfWidth"} required>
                                                <label htmlFor="cognome">Cognome</label>
                                                <input className={"form-control"} type="text" name="cognome" required/>
                                            </FormControl>
                                            <FormControl className={"halfWidth"} required>
                                                <label htmlFor="username">Username</label>
                                                <input className={"form-control"} type="text" name="username" required/>
                                            </FormControl>
                                            <div style={{margin: 0}} className="row">
                                                <FormControl className={"halfWidth"} required>
                                                    <label htmlFor="email">Email</label>
                                                    <input className={"form-control"} type="email" name="email" required/>
                                                </FormControl>
                                                <FormControl className={"halfWidth"} required>
                                                    <label htmlFor="cellulare">Cellulare</label>
                                                    <input className={"form-control"} type="text" name="cellulare" required/>
                                                </FormControl>
                                            </div>
                                             <div style={{margin: 0}} className="row">
                                                <FormControl className={"halfWidth"} required>
                                                    <label htmlFor="password">Password</label>
                                                    <input className={"form-control"} type={this.state.hidePwd ? "password" : "text"} name="password" required/>
                                                    {
                                                        this.state.hidePwd ?
                                                            <VisibilityIcon className={"myEyeButton"} onClick={() => this.showHidePwd()} /> :
                                                            <VisibilityOffIcon className={"myEyeButton"} onClick={() => this.showHidePwd()} />
                                                    }
                                                </FormControl>
                                                 {this.state.isPrivateUser ?
                                                     (
                                                         <FormControl className={"halfWidth"}>
                                                             <label htmlFor="codiceFiscale">Codice Fiscale <span style={{fontSize: "10px", color: "grey"}}>Opzionale</span></label>
                                                             <input className={"form-control"} type="text" name="codiceFiscale"/>
                                                         </FormControl>
                                                     ):
                                                     (
                                                         <FormControl className={"halfWidth"} required>
                                                             <label htmlFor="pIva">Partita Iva</label>
                                                             <input className={"form-control"} type="text" name="pIva" required/>
                                                         </FormControl>
                                                     )
                                                 }
                                                 {this.state.isPrivateUser ?
                                                     (
                                                         <span></span>
                                                     ):
                                                     (
                                                         <FormControl className={"myFormWidth"} required>
                                                             <label htmlFor="azienda">Ragione Sociale</label>
                                                             <input className={"form-control"} type="text" name="azienda" required/>
                                                         </FormControl>
                                                     )
                                                 }
                                            </div>

                                        </div>
                                        <br/>
                                        <h5>Indirizzo ritiro</h5>
                                        <div className="myCustomModalAddress">
                                            <FormControl className={"halfWidth"} required>
                                                <label htmlFor="via">Via/Viale</label>
                                                <input className={"form-control"} type="text" name="via" required/>
                                            </FormControl>
                                            <FormControl className={"halfWidth"} required>
                                                <label htmlFor="numero">Numero civico</label>
                                                <input className={"form-control"} type="text" name="numero" required/>
                                            </FormControl>
                                            <FormControl className={"halfWidth"} required>
                                                <label htmlFor="citta">Città</label>
                                                <input className={"form-control"} type="text" name="citta" required/>
                                            </FormControl>
                                            <FormControl className={"halfWidth"} required>
                                                <label htmlFor="regione">Regione</label>
                                                <input className={"form-control"} type="text" name="regione" required/>
                                            </FormControl>
                                            <FormControl className={"halfWidth"} required>
                                                <label htmlFor="cap">CAP</label>
                                                <input className={"form-control"} type="text" name="cap" required/>
                                            </FormControl>
                                            <FormControl className={"halfWidth"} required>
                                                <label htmlFor="provincia">Provincia</label>
                                                <input className={"form-control"} type="text" name="provincia" required/>
                                            </FormControl>

                                        </div>
                                    </span>)
                            }
                        </DialogContent>
                        <DialogActions style={{padding: 23}}>
                            {/*<button onClick={() => this.handleClose()}
                                    className="myButtonDisabled"> Annulla </button>*/}
                            <button style={{width: "100%"}} type="submit"
                                    color="primary"
                                    className={"myButton"}>
                                { this.state.recoverPwd ? "Recupera password"  : this.state.login ? "Accedi" : "Registrati"}
                            </button>
                        </DialogActions>
                    </form>

                </Dialog>
            </div>
        );
    }
}
