import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Autocomplete from '@material-ui/lab/Autocomplete';
import InputAdornment from "@material-ui/core/InputAdornment";
import {Input, InputLabel, MenuItem, OutlinedInput, Select, TextField} from "@material-ui/core";
import SettingsIcon from "@material-ui/icons/PhoneIphone";
import GroupAddIcon from "@material-ui/icons/Build";
import ImageIcon from '@material-ui/icons/Image';
import VideoLabelIcon from "@material-ui/icons/AttachMoney";
import InfoIcon from "@material-ui/icons/Info";
import clsx from "clsx";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';
import Popover from '@material-ui/core/Popover';
import {Link} from "react-router-dom";
import '@uppy/core/dist/style.css'
import '@uppy/drag-drop/dist/style.css'
import {alignPropType} from "react-bootstrap/DropdownMenu";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";

const Italian = require('@uppy/locales/lib/it_IT') // see below for the full list of locales
const { Dashboard, DashboardModal, DragDrop, ProgressBar } = require('@uppy/react')
const Tus = require('@uppy/tus')


const Uppy = require('@uppy/core')


const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    button: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    smallFont: {
        fontWeight: 200
    },
    label: {
        fontWeight: 200
    },
    radio: {
        '&$checked': {
            color: '#5ba4a0'
        }
    },
    checked: {}
}));

function getSteps() {
    return ['Modello', 'Usura', 'Foto', 'Prezzo'];
}


function getStepContent(anchorEl, handleClick, handleClose, open, id, classes, step, modelli, modello, colori, colore, storage, taglia, valueUsura, valueUsuraSchermo, valueTasti, prezzo, saveModelSelected, saveColorSelected, saveSizeSelected, saveUsura, saveUsuraSchermo, saveTasti, handlePrezzoChange, domande, saveAnswer, arrAnswer, produttori, produttore,saveProduttoreSelected,saveAdditionalData,additionalAnswer,extraArray,uppy,descrizione, prezzoMedia) {

    switch (step) {
        case 0:
            return <div>
                <h2>Modello</h2>
                <br/>
                <Typography style={{textAlign: "left"}} variant="p">Inserisci il brand del tuo dispositivo</Typography>
                <br/>
                {/*<Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Grid container justify="center" spacing={2}>
                            {modelli?.map((value) => (
                                <Grid key={value.name} item>
                                    <button className="mySelectButton"> {value.name}</button>
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                </Grid>*/}
                <Select
                    native
                    id="modello"
                    variant="outlined"
                    style={{width: "100%", fontWeight: 350}}
                    value={produttore && produttore != null ? produttore : produttore}
                    classes={{input: classes.smallFont}}
                    onChange={saveProduttoreSelected}
                >
                    <option value="" disabled>
                        --Seleziona--
                    </option>
                    {produttori && produttori.map(com => (
                        <option key={com.nomeProduttore} value={com.idProduttore} name={com.nomeProduttore}>
                            {com.nomeProduttore}
                        </option>
                    ))}
                </Select>
                <br/>
                <br/>
                <Typography style={{textAlign: "left"}} variant="p">Inserisci il modello del tuo dispositivo</Typography>
                <br/>
                {/*<Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Grid container justify="center" spacing={2}>
                            {modelli?.map((value) => (
                                <Grid key={value.name} item>
                                    <button className="mySelectButton"> {value.name}</button>
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                </Grid>*/}
                <Select
                    native
                    id="modello"
                    variant="outlined"
                    style={{width: "100%", fontWeight: 350}}
                    value={modello.idProdotto != null ? modello.idProdotto : modello}
                    classes={{input: classes.smallFont}}
                    onChange={saveModelSelected}
                >
                    <option value="" disabled>
                        --Seleziona--
                    </option>
                    {modelli && modelli.map(com => (
                        <option key={com.nomeProdotto} value={com.idProdotto} name={com.nomeProdotto}>
                            {com.nomeProdotto}
                        </option>
                    ))}
                </Select>
                {domande == null || domande.length == 0 ? (
                    <span>
                        <br/>
                        <br/>
                        <Typography style={{textAlign: "left"}}
                                    variant="p">Inserisci il colore del tuo dispositivo</Typography>
                        <br/>
                        <Select
                            native
                            id="colore"
                            variant="outlined"
                            style={{width: "100%", fontWeight: 350}}
                            value={""}
                            classes={{input: classes.smallFont}}
                        >
                            <option value="" disabled>
                                --Seleziona--
                            </option>

                        </Select>
                        <br/>
                        <br/>
                        <Typography style={{textAlign: "left"}}
                                    variant="p">Inserisci la capacità del tuo dispositivo</Typography>
                        <br/>
                        <Select
                            native
                            id="colore"
                            variant="outlined"
                            style={{width: "100%", fontWeight: 350}}
                            classes={{input: classes.smallFont}}
                            value={""}
                        >
                            <option value="" disabled>
                                --Seleziona--
                            </option>

                        </Select>
                    </span>
                ) : domande && domande.length > 0 && domande.map((nodo, index) => nodo.posizione == 1 ? (
                    <span>
                        <br/>
                        <br/>
                        <Typography style={{textAlign: "left"}} variant="p">{nodo.titoloDomanda}</Typography>
                        <span className={nodo.infoDomanda == null || nodo.infoDomanda == "" ? "notDisplay" : ""}>
                            <span className={"myHelper"} aria-describedby={id} onClick={handleClick}>
                            <InfoIcon fontSize={"small"}/>Informazioni
                        </span>
                            {/*TODO controllo se ci sono due info nella stessa pagina bug*/}
                        <Popover
                            id={id}
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                        >
                            <div style={{padding: 15}}>
                                <InfoIcon fontSize={"small"}/> Informazioni <br/>
                                <p className={"myHelperText"}>
                                    {nodo.infoDomanda}
                                </p>
                            </div>
                        </Popover>
                        </span>
                        <br/>
                        <Select
                            native
                            id="colore"
                            variant="outlined"
                            style={{width: "100%", fontWeight: 350}}
                            value={arrAnswer && arrAnswer.length > 0 ? JSON.stringify(arrAnswer?.find(obj => {
                                return obj.idDomanda == nodo.idDomanda && obj?.idRisposta != null;
                            })) : "-1"
                            }
                            classes={{input: classes.smallFont}}
                            onChange={saveAnswer}
                        >
                            <option value="-1" disabled>
                                --Seleziona--
                            </option>
                            {nodo.risposte && nodo.risposte.map(com => (
                                <option key={com.idRisposta} value={JSON.stringify(com)} name={com.testoRisposta}>
                                    {com.testoRisposta}
                                </option>
                            ))}
                        </Select>
                    </span>
                ) : <span></span>)

                }
            </div>;
        case 1:
            return <div>
                {extraArray.length >= 3 ?
                    <div className="row">
                        <div className="col-sm">
                            <h2>Condizioni dispositivo</h2>
                            <br/>
                            {domande && domande.length > 0 && domande.map((nodo, index) => nodo.posizione == 2 ? (
                                <span>
                        <Typography style={{textAlign: "left"}} variant="p">{nodo.titoloDomanda}</Typography>
                                                <span
                                                    className={nodo.infoDomanda == null || nodo.infoDomanda == "" ? "notDisplay" : ""}>
                            <span className={"myHelper"} aria-describedby={id} onClick={handleClick}>
                            <InfoIcon fontSize={"small"}/>Informazioni
                        </span>
                        <Popover
                            id={id}
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                        >
                            <div style={{padding: 15}}>
                                <InfoIcon fontSize={"small"}/> Informazioni <br/>
                                <p className={"myHelperText"}>
                                    {nodo.infoDomanda}
                                </p>
                            </div>
                        </Popover>
                        </span>
                        <br/>
                        <br/>
                        <FormControl component="fieldset">
                        <RadioGroup row aria-label="batteria" name="batteria"
                                    value={arrAnswer && arrAnswer.length > 0 ? JSON.stringify(arrAnswer?.find(obj => {
                                        return obj.idDomanda == nodo.idDomanda && obj?.idRisposta != null;
                                    })) : "-1"
                                    } onChange={(e) => saveAnswer(e)}>
                             {nodo.risposte && nodo.risposte.map(com => (
                                 <FormControlLabel classes={{label: classes.label}}
                                                   value={JSON.stringify(com)}
                                                   control={<Radio
                                                       classes={{root: classes.radio, checked: classes.checked}}/>}
                                                   label={com.testoRisposta}>
                                     {com.testoRisposta}
                                 </FormControlLabel>
                             ))}
                        </RadioGroup>
                    </FormControl>
                        <br/>
                        <br/>
                    </span>
                            ) : <span></span>)
                            }
                        </div>
                        <div className="col-sm">
                            <h2>Funzionalità dispositivo</h2>
                            <br/>
                            {extraArray.map((element) => {
                                if(!element.finalQuestions) {
                                    return <>
                                        <Typography style={{textAlign: "left"}} variant="p">{element.domanda}</Typography>
                                        <br/>
                                        {element.type == 1 ? <br/> : <></> }
                                        <FormControl  className={"myFormWidth"}>
                                            {element.type == 1 ?
                                                <RadioGroup row aria-label="batteria" name="batteria"
                                                            value={additionalAnswer?.filter((elm) => elm['key'] == element.key)[0]?.value} onChange={(e) => saveAdditionalData({"key": element.key, "value": e.target.value, "label": element.label})}>
                                                    {
                                                        element.risposte.map((risposta) =>
                                                            <FormControlLabel classes={{label: classes.label}}
                                                                              value={risposta}
                                                                              control={<Radio
                                                                                  classes={{root: classes.radio, checked: classes.checked}}/>}
                                                                              label={risposta}>
                                                                {risposta}
                                                            </FormControlLabel>
                                                        )
                                                    }
                                                </RadioGroup> :
                                                <TextField name="annoProduzione"
                                                           id="outlined-basic"
                                                           variant="outlined"
                                                           onChange={(e) => {
                                                               saveAdditionalData({"key": element.key, "value": e.target.value, "label": element.label})
                                                           }}
                                                />
                                            }
                                        </FormControl>
                                        {element.type == 1 ? <br/> : <></> }
                                        {element.type == 1 ? <br/> : <></> }
                                    </>
                                }
                            })}
                        </div>
                    </div> :
                    <div className="row" style={{textAlign: "center"}}>
                        <div className="col-lg">
                            <h2>Condizioni dispositivo</h2>
                            <br/>
                            {domande && domande.length > 0 && domande.map((nodo, index) => nodo.posizione == 2 ? (
                                <span>
                        <Typography style={{textAlign: "left"}} variant="p">{nodo.titoloDomanda}</Typography>
                                                <span
                                                    className={nodo.infoDomanda == null || nodo.infoDomanda == "" ? "notDisplay" : ""}>
                            <span className={"myHelper"} aria-describedby={id} onClick={handleClick}>
                            <InfoIcon fontSize={"small"}/>Informazioni
                        </span>
                        <Popover
                            id={id}
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                        >
                            <div style={{padding: 15}}>
                                <InfoIcon fontSize={"small"}/> Informazioni <br/>
                                <p className={"myHelperText"}>
                                    {nodo.infoDomanda}
                                </p>
                            </div>
                        </Popover>
                        </span>
                        <br/>
                        <br/>
                        <FormControl component="fieldset">
                        <RadioGroup row aria-label="batteria" name="batteria"
                                    value={arrAnswer && arrAnswer.length > 0 ? JSON.stringify(arrAnswer?.find(obj => {
                                        return obj.idDomanda == nodo.idDomanda && obj?.idRisposta != null;
                                    })) : "-1"
                                    } onChange={(e) => saveAnswer(e)}>
                             {nodo.risposte && nodo.risposte.map(com => (
                                 <FormControlLabel classes={{label: classes.label}}
                                                   value={JSON.stringify(com)}
                                                   control={<Radio
                                                       classes={{root: classes.radio, checked: classes.checked}}/>}
                                                   label={com.testoRisposta}>
                                     {com.testoRisposta}
                                 </FormControlLabel>
                             ))}
                        </RadioGroup>
                    </FormControl>
                        <br/>
                        <br/>
                    </span>
                            ) : <span></span>)
                            }
                        </div>
                    </div>
                }


            </div>;
        case 2:
            return <div>
                <h2>Ultimi dettagli</h2>
                <br/>
               <div className="row">
                   <div className="col-sm">
                       <Typography style={{textAlign: "left"}} variant="p">Carica delle foto per mostrare il tuo dispositivo</Typography>
                       <br/><span style={{fontSize: 13, color: "#8a8b8d"}}>Carica fino a 6 immagini, dimensione massima consentita 700kb</span>
                       <Dashboard
                           uppy={uppy}
                           inline={true}
                           height={400}
                           width={"90%"}
                           note={'Carica fino a 6 immagini, dimensione massima consentita 700kb'}
                           disableInformer={false}
                           proudlyDisplayPoweredByUppy={false}
                           showProgressDetails={false}
                           disableStatusBar={true}
                       />
                   </div>
                   {extraArray.length >= 0 ?  <div className="col-sm">
                       <h3>Stato scatola e accessori</h3>
                       <br/>
                       {extraArray.map((element) => {
                           if(element.finalQuestions) {
                               return <>
                                   <Typography style={{textAlign: "left"}} variant="p">{element.domanda}</Typography>
                                   <br/>
                                   {element.type == 1 ? <br/> : <></> }
                                   <FormControl  className={"myFormWidth"}>
                                       {element.type == 1 ?
                                           <RadioGroup row aria-label="batteria" name="batteria"
                                                       value={additionalAnswer?.filter((elm) => elm['key'] == element.key)[0]?.value} onChange={(e) => saveAdditionalData({"key": element.key, "value": e.target.value, "label": element.label})}>
                                               {
                                                   element.risposte.map((risposta) =>
                                                       <FormControlLabel classes={{label: classes.label}}
                                                                         value={risposta}
                                                                         control={<Radio
                                                                             classes={{root: classes.radio, checked: classes.checked}}/>}
                                                                         label={risposta}>
                                                           {risposta}
                                                       </FormControlLabel>
                                                   )
                                               }
                                           </RadioGroup> :
                                           <TextField name="annoProduzione"
                                                      id="outlined-basic"
                                                      variant="outlined"
                                                      onChange={(e) => {
                                                          saveAdditionalData({"key": element.key, "value": e.target.value, "label": element.label})
                                                      }}
                                           />
                                       }
                                   </FormControl>
                                   {element.type == 1 ? <br/> : <></> }
                                   {element.type == 1 ? <br/> : <></> }
                               </>
                           }
                       })}
                       <Typography style={{textAlign: "left"}} variant="p">Vuoi aggiungere una breve descrizione al tuo annuncio? (minimo 100 caratteri) <span className={"myMaxText"}>{(descrizione?.length?.toString() || "0")}</span></Typography>
                       <textarea style={{width: "100%"}} rows="3" onChange={(e) => {
                           saveAdditionalData({"key": "descrizione", "value": e.target.value, "label": "Descrizione"})
                       }}></textarea>
                   </div> : ""}

               </div>
            </div>;
        case 3:
            return <div className="row">
                <div className="col-sm myResume">
                    <br/>
                    <h5>Riepilogo</h5>
                    <table>
                         <span>
                            <tr>
                                <th>Modello</th>
                            </tr>
                            <tr>
                                <td>{modello.nomeProdotto}</td>
                            </tr>
                        </span>
                        {arrAnswer && arrAnswer.length > 0 && arrAnswer.map((nodo, index) => (
                            <span>
                                     <tr>
                                         <th>{nodo.descrizioneRisposta}</th>
                                    </tr>
                                    <tr>
                                        <td>{nodo.testoRisposta}</td>
                                    </tr>
                                </span>

                        ))
                        }
                        {additionalAnswer && additionalAnswer.length > 0 && additionalAnswer.map((nodo, index) => (
                            <span>
                                     <tr>
                                         <th>{nodo.label}</th>
                                    </tr>
                                    <tr>
                                        <td>{nodo.value}</td>
                                    </tr>
                                </span>

                        ))
                        }

                    </table>
                    <br/>
                </div>
                <div className="col-sm">
                    <br/>
                    <h2>Ci sei quasi !</h2>
                    <br/>
                    <p>Aggiungi il prezzo di vendita per pubblicare il tuo annuncio su <span
                        style={{fontWeight: 500}}>Validato!</span></p>
                    <br/>
                    <p>Prezzo Medio del tuo prodotto: {prezzoMedia != 0  ? parseInt(prezzoMedia) + " €" : "Non abbiamo questi dati disponibli al momento"}</p>
                    <InputLabel htmlFor="name-prod">Prezzo di vendita</InputLabel>
                    <OutlinedInput inputProps={{min: "0", max: "100000", step: "1", inputMode: 'numeric', pattern: '[0-9]*'}}
                                   startAdornment={<InputAdornment position="start">€</InputAdornment>}
                                   id="name-prod"
                                   type="number"
                                   style={{width: "100%", fontWeight: 350}}
                                   placeholder={0}
                                   value={prezzo} onChange={handlePrezzoChange}

                    />
                    <br/><br/>
                    <p className={"myHideInfo"}>Commissione Validato <span style={{fontWeight: "bold"}}>0€</span></p>
                    {
                        prezzo - 15 > 0 && <p className={"myHideInfo"}>Al termine della tua vendita riceverai <span style={{fontWeight: "bold"}}>{prezzo} €</span></p>
                    }
                </div>
            </div>;
        default:
            return 'Unknown step';
    }
}

export default function HorizontalLinearStepper({
                                                    firstCheck,
                                                    modello,
                                                    colore,
                                                    taglia,
                                                    callback,
                                                    modelli,
                                                    colori,
                                                    storage,
                                                    valueUsura,
                                                    valueUsuraSchermo,
                                                    valueTasti,
                                                    saveModelSelected,
                                                    saveColorSelected,
                                                    saveSizeSelected,
                                                    saveUsura,
                                                    saveUsuraSchermo,
                                                    saveTasti,
                                                    prezzo,
                                                    handlePrezzoChange,
                                                    domande,
                                                    saveAnswer,
                                                    arrAnswer,
                                                    produttori,
                                                    produttore,
                                                    saveProduttoreSelected,
                                                    saveAdditionalData,
                                                    additionalAnswer,
                                                    extraArray,
                                                    images,
                                                    saveImagesInState,
                                                    uppy,
                                                    descrizione,
                                                    prezzoMedia

                                                }) {
    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [skipped, setSkipped] = React.useState(new Set());
    const steps = getSteps();

    const isStepOptional = (step) => {
        return step === -1;
    };
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const isStepSkipped = (step) => {
        return skipped.has(step);
    };
    const useColorlibStepIconStyles = makeStyles({
        root: {
            backgroundColor: '#ccc',
            zIndex: 1,
            color: '#fff',
            width: 50,
            height: 50,
            display: 'flex',
            borderRadius: '50%',
            justifyContent: 'center',
            alignItems: 'center',
        },
        active: {
            backgroundColor: '#5ba4a0',
        },
        completed: {
            backgroundColor: '#5ba4a0',
        },
    });

    function ColorlibStepIcon(props) {
        const classes = useColorlibStepIconStyles();
        const {active, completed} = props;

        const icons = {
            1: <SettingsIcon/>,
            2: <GroupAddIcon/>,
            3: <ImageIcon/>,
            4: <VideoLabelIcon/>,
        };

        return (
            <div
                className={clsx(classes.root, {
                    [classes.active]: active,
                    [classes.completed]: completed,
                })}
            >
                {icons[String(props.icon)]}
            </div>
        );
    }


    const handleNext = () => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
        window.scrollTo(0, 0)
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleSkip = () => {
        if (!isStepOptional(activeStep)) {
            // You probably want to guard against something like this,
            // it should never occur unless someone's actively trying to break something.
            //throw new Error("You can't skip a step that isn't optional.");
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped((prevSkipped) => {
            const newSkipped = new Set(prevSkipped.values());
            newSkipped.add(activeStep);
            return newSkipped;
        });
    };

    const handleReset = () => {
        setActiveStep(0);
    };
    return (
        <div className={classes.root}>
            <Stepper position="top" activeStep={activeStep}>
                {steps.map((label, index) => {
                    const stepProps = {};
                    const labelProps = {};

                    /*if (isStepOptional(index)) {
                        labelProps.optional = <Typography variant="caption">Optional</Typography>;
                    }
                    if (isStepSkipped(index)) {
                        stepProps.completed = false;
                    }*/
                    return (
                        <Step style={{color: "red"}} key={label} {...stepProps}>
                            <StepLabel StepIconComponent={ColorlibStepIcon} {...labelProps}>{""}</StepLabel>
                        </Step>
                    );
                })}
            </Stepper>
            <div>
                {activeStep === steps.length ? (
                    <div>
                        <Typography className={classes.instructions}>
                            All steps completed - you&apos;re finished
                        </Typography>
                        <Button onClick={handleReset} className={classes.button}>
                            Reset
                        </Button>
                    </div>
                ) : (
                    <div>
                        <div className="row">
                            <div className="col-sm">
                            </div>
                            <div style={{padding: "30px"}} className="col-sm-9">
                                {getStepContent(anchorEl, handleClick, handleClose, open, id, classes, activeStep, modelli, modello, colori, colore, storage, taglia, valueUsura, valueUsuraSchermo, valueTasti, prezzo, saveModelSelected, saveColorSelected, saveSizeSelected, saveUsura, saveUsuraSchermo, saveTasti, handlePrezzoChange, domande, saveAnswer, arrAnswer, produttori, produttore,saveProduttoreSelected,saveAdditionalData,additionalAnswer,extraArray,uppy,descrizione,prezzoMedia)}
                                <br/>

                                <div style={{textAlign: "center"}}>
                                    <button style={{float: "left"}} disabled={activeStep === 0} onClick={handleBack}
                                            className={"myButtonDisabled"}>
                                        Indietro
                                    </button>
                                    <button
                                        disabled={
                                            (activeStep === 0 && arrAnswer?.length < domande?.filter(obj => {
                                            return obj.posizione === 1
                                        }).length || !domande) ||
                                        (activeStep === 1 && (arrAnswer?.length < domande?.filter(obj => {
                                            return obj.posizione === 1
                                        }).length + domande?.filter(obj => {
                                            return obj.posizione === 2
                                        }).length || (additionalAnswer == null && extraArray.length > 2) || additionalAnswer?.length < extraArray.length - 2 ))
                                        || (activeStep === 2 && (images == null || Object.keys(images).length == 0 || descrizione == null || descrizione == "" || descrizione.length < 100 || additionalAnswer.length < extraArray.length))
                                        || activeStep == 3 && prezzo <= 0}
                                        color="primary"
                                        onClick={activeStep === steps.length - 1 ?  callback : handleNext}
                                        className={"myButton"}
                                    >
                                        {activeStep === steps.length - 1 ? 'Prosegui' : 'Avanti'}
                                    </button>
                                </div>

                                {/*{isStepOptional(activeStep) && (
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleSkip}
                                        className={classes.button}
                                    >
                                        Skip
                                    </Button>
                                )}*/}
                            </div>
                            <div className="col-sm">
                            </div>
                        </div>

                    </div>
                )}
            </div>
        </div>
    );
}
